import "./AgeGateFailed.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../data/data";
import gsap from "gsap";
import useStore from "../../store";
// import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";

function AgeGateFailed(props) {
  const appState = useStore((state) => state.appState);
  const setAppState = useStore((state) => state.setAppState);
  const setIsPlacingBoard = useStore((state) => state.setIsPlacingBoard);
  const setIsARActive = useStore((state) => state.setIsARActive);
  const setShowAgeGate = useStore((state) => state.setShowAgeGate);

  const setPlaySound = useStore((state) => state.setPlaySound);

  const el = useRef();
  const tl = useRef();
  const title = useRef();
  const text = useRef();
  const eighthWallLogo = useRef();
  const fritoLogo = useRef();
  const hasbroLogo = useRef();
  const verify = useRef();
  const verifyBlock = useRef();
  const startButton = useRef();
  const legal = useRef();

  useEffect(() => {
    if (tl.current) tl.current.pause();
    if (appState === "ageGateFailed") {
      tl.current = gsap.timeline({ delay: 0.5 });
      tl.current.set(el.current, { autoAlpha: 1, y: 500, zIndex: 2100 });

      tl.current.to(el.current, {
        autoAlpha: 1,
        y: 0,
        duration: 0.5,
        ease: "back.out",
      });
    }
  }, [appState]);

  const buttonClicked = () => {
    setPlaySound("click");
    window.location.reload();
  };

  return (
    <div ref={el} className={`age-gate-failed`}>
      <div className="card">
        <img
          className="title"
          src="../../assets/images/age-gate-failed-title.png"
          alt="title"
        />
        <p ref={text} className="text">
          You must be 18 years of age or older to play.
        </p>
        <button
          ref={startButton}
          className="back-button"
          onClick={buttonClicked}
          id="age-gate-failed-back-button"
        >
          <img src="../../assets/images/back-button.png" alt="back" />
        </button>
      </div>
      {/* <button
        onClick={() => {
          localStorage.removeItem("passedAgeGate");
          // clear anything else we stoted
          window.location.reload();
        }}
        id="age-gate-failed-reset-button"
        className="clear"
      >
        Reset Game
      </button> */}
    </div>
  );
}

export default AgeGateFailed;
