import gsap from "gsap";
import "./DesktopLanding.scss";
import { useEffect, useRef } from "react";
// import gsap from "gsap";

// import turtles from "../../assets/images/desktop-turtles.png";
// import background from "../../assets/images/desktop-background.jpg";
// import eighthWallLogo from "../../assets/images/8th-wall-logo.svg";
// import qrCode from "../../assets/images/desktop-qr-code.png";
// import filmDate from "../../assets/images/desktop-date.png";
// import Footer from "../Footer/Footer";

// import useStore from "../../store";

function DesktopLanding(props) {
  const tl = useRef();
  const el = useRef();
  const background = useRef();
  const title = useRef();
  const text = useRef();
  const qrCode = useRef();
  const fritoLogo = useRef();
  const hasbroLogo = useRef();

  useEffect(() => {
    tl.current = gsap.timeline({ delay: 1.0 });

    tl.current.fromTo(
      [
        fritoLogo.current,
        hasbroLogo.current,
        title.current,
        text.current,
        qrCode.current,
      ],
      { y: 50, autoAlpha: 0 },
      {
        y: 0,

        autoAlpha: 1,
        stagger: 0.1,
        duration: 0.5,
        ease: "sine.out",
      }
    );
  }, []);

  return (
    <div ref={el} className={`desktop-landing`}>
      <img
        src={"../../assets/images/desktop-background.jpg"}
        alt=""
        className="background"
        ref={background}
      />
      <div className="center-group">
        <div className="logos">
          <img
            className="frito-logo"
            ref={fritoLogo}
            src="../../assets/images/frito-logo.png"
            alt=""
          />
          <img
            className="hasbro-logo"
            ref={hasbroLogo}
            src="../../assets/images/hasbro-logo.png"
            alt=""
          />
        </div>
        <img
          className="titles"
          ref={title}
          src="../../assets/images/titles.png"
          alt=""
        />
        <p className="text" ref={text}>
          Scan this QR code with your phone to start the experience.
        </p>
        <img
          ref={qrCode}
          src={"../../assets/images/qr-code.png"}
          alt={""}
          className="qr-code"
        />
      </div>
    </div>
  );
}

export default DesktopLanding;
