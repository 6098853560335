import "./MiniLoadingBar.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../data/data";
import useStore from "../../store";
import gsap from "gsap";
// import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";

function MiniLoadingBar(props) {
  const loadingProgress = useStore((state) => state.loadingProgress);
  const showLoader = useStore((state) => state.showLoader);

  const el = useRef();
  const tl = useRef();

  useEffect(() => {
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline();

    if (showLoader) {
      // show
      tl.current.set(el.current, { autoAlpha: 1, zIndex: 3200 });
      tl.current.to([el.current], {
        autoAlpha: 1,
        duration: 0.1,
      });
    } else {
      tl.current.to([el.current], {
        autoAlpha: 0,
        duration: 0.5,
      });
      tl.current.set(el.current, { autoAlpha: 0, zIndex: -1 });
    }
  }, [showLoader]);

  return (
    <div ref={el} className="mini-loading-bar">
      <div
        className="progress"
        style={{ width: loadingProgress * 100 + "%" }}
      ></div>
      <div className="progress-text">
        {Math.round(loadingProgress * 100) + "%"}
      </div>
    </div>
  );
}

export default MiniLoadingBar;
