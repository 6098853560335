import { create } from "zustand";

const useStore = create((set) => ({
  // COMING SOON  - Set appState="comingSoon" in store.js
  // Live Site  - Set appState="landing" in store.js

  appState: "landing", // landing
  setAppState: (string) => set(() => ({ appState: string })),

  gameState: null, // starting, waitingToRoll, Rolling, moving, playingAnimation, collectingSnack, complete
  setGameState: (string) => set(() => ({ gameState: string })),

  isARActive: false,
  setIsARActive: (bool) => set(() => ({ isARActive: bool })),

  isARInFocus: true,
  setIsARInFocus: (bool) => set(() => ({ isARInFocus: bool })),

  isPlacingBoard: false,
  setIsPlacingBoard: (bool) => set(() => ({ isPlacingBoard: bool })),

  showAgeGate: false,
  setShowAgeGate: (bool) => set(() => ({ showAgeGate: bool })),

  showPermissionError: false,
  setShowPermissionError: (bool) => set(() => ({ showPermissionError: bool })),

  showInstructions: false,
  setShowInstructions: (bool) => set(() => ({ showInstructions: bool })),

  showInstructionsButton: false,
  setShowInstructionsButton: (bool) =>
    set(() => ({ showInstructionsButton: bool })),

  rollNumber: null,
  setRollNumber: (val) => set(() => ({ rollNumber: val })),

  currentPosition: 0,
  setCurrentPosition: (val) => set(() => ({ currentPosition: val })),

  currentZone: "start", //  start, life, scrabble, sorry, connect4 ,  monopoly,  end
  setCurrentZone: (string) => set(() => ({ currentZone: string })),

  activeAnimation: null,
  setActiveAnimation: (string) => set(() => ({ activeAnimation: string })),

  snacksCollected: 0,
  setSnacksCollected: (val) => set(() => ({ snacksCollected: val })),

  showLoader: true,
  setShowLoader: (bool) => set(() => ({ showLoader: bool })),

  loadingProgress: 0,
  setLoadingProgress: (val) => set(() => ({ loadingProgress: val })),

  // isDropActive: true,
  // setIsDropActive: (bool) => set(() => ({ isDropActive: bool })),

  // isVPSButtonActive: true,
  // setIsVPSButtonActive: (bool) => set(() => ({ isVPSButtonActive: bool })),

  // needToShowKeepGoing: false,
  // setNeedToShowKeepGoing: (bool) => set(() => ({ needToShowKeepGoing: bool })),

  // isAtVidCon: null,
  // setIsAtVidCon: (bool) => set(() => ({ isAtVidCon: bool })),

  // isAtManhole: null,
  // setIsAtManhole: (bool) => set(() => ({ isAtManhole: bool })),

  // isVPSActive: false,
  // setIsVPSActive: (bool) => set(() => ({ isVPSActive: bool })),

  // showTipJar: false,
  // setShowTipJar: (bool) => set(() => ({ showTipJar: bool })),

  // showTipInspector: false,
  // setShowTipInspector: (bool) => set(() => ({ showTipInspector: bool })),

  // instantWinPrize: null,
  // setInstantWinPrize: (number) => set(() => ({ instantWinPrize: number })),

  // instantWinPrizeId: null,
  // setInstantWinPrizeId: (string) => set(() => ({ instantWinPrizeId: string })),

  // instantWinMessageId: null,
  // setInstantWinMessageId: (string) =>
  //   set(() => ({ instantWinMessageId: string })),

  // tipIndex: null,
  // setTipIndex: (number) => set(() => ({ tipIndex: number })),

  // goldTipsCollected: [],
  // setGoldTipsCollected: (array) => set(() => ({ goldTipsCollected: array })),

  // tipsCollected: [],
  // setTipsCollected: (array) => set(() => ({ tipsCollected: array })),

  // tipInspectorIndex: null,
  // setTipInspectorIndex: (number) => set(() => ({ tipInspectorIndex: number })),

  // showCameraCapture: false,
  // setShowCameraCapture: (bool) => set(() => ({ showCameraCapture: bool })),

  // vpsLocationTitle: null,
  // setVpsLocationTitle: (string) => set(() => ({ vpsLocationTitle: string })),

  // vpsLocationDistance: null,
  // setVpsLocationDistance: (string) =>
  //   set(() => ({ vpsLocationDistance: string })),

  // placeManhole: false,
  // setPlaceManhole: (bool) => set(() => ({ placeManhole: bool })),

  soundOn: localStorage.getItem("soundOn")
    ? localStorage.getItem("soundOn") === "true"
      ? true
      : false
    : true,
  setSoundOn: (bool) => set(() => ({ soundOn: bool })),

  playSound: null,
  setPlaySound: (string) => set(() => ({ playSound: string })),

  stopSound: null,
  setStopSound: (string) => set(() => ({ stopSound: string })),
}));

export default useStore;
