import "./ZoneUI.scss";
import { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import { data } from "../../../data/data";
import useStore from "../../../store";
import gsap from "gsap";
// import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";
import ImageSeqeuncePlayer from "../../ImageSeqeuncePlayer/ImageSeqeuncePlayer";

function ZoneUI(props) {
  const setPlaySound = useStore((state) => state.setPlaySound);
  const setStopSound = useStore((state) => state.setStopSound);
  const currentZone = useStore((state) => state.currentZone);

  const el = useRef();
  const tl = useRef();
  const zone1 = useRef();
  const zone2 = useRef();
  const zone3 = useRef();
  const zone4 = useRef();
  const zone5 = useRef();

  useEffect(() => {
    if (!currentZone) return;

    if (currentZone === "start") {
      setPlaySound("zone 1");
    }

    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline();
    let z;
    let zz;

    if (currentZone === "life") {
      z = zone1;
      setPlaySound("zone 1");
    }
    if (currentZone === "scrabble") {
      z = zone2;
      zz = zone1;
      setPlaySound("zone 2");
      setStopSound("zone 1");
    }
    if (currentZone === "sorry") {
      z = zone3;
      zz = zone2;
      setPlaySound("zone 3");
      setStopSound("zone 2");
    }
    if (currentZone === "connect4") {
      z = zone4;
      zz = zone3;
      setPlaySound("zone 4");
      setStopSound("zone 3");
    }
    if (currentZone === "monopoly") {
      z = zone5;
      zz = zone4;
      setPlaySound("zone 5");
      setStopSound("zone 4");
    }
    if (currentZone === "end") {
      zz = zone5;
      setStopSound("zone 5");
      setPlaySound("end");
    }

    tl.current.set(el.current, { zIndex: 2100, autoAlpha: 1 });
    if (zz) {
      tl.current.to(zz.current, {
        y: -200,
        scale: 0.5,
        autoAlpha: 0,
        ease: "back.in",
      });
    }
    if (z) {
      tl.current.fromTo(
        z.current,
        { y: -200, scale: 0.5, autoAlpha: 0 },
        {
          autoAlpha: 1,
          y: 0,
          scale: 1,
          duration: 0.5,
          ease: "back.out",
        }
      );
    }

    // tl.current.set(el.current, { zIndex: -1, autoAlpha: 0 });
  }, [currentZone]);

  return (
    <div ref={el} className="zone-ui">
      <div ref={zone1} className="zone zone-1">
        <img src={"../../assets/images/zone-1.png"} className="base" alt="" />
      </div>
      <div ref={zone2} className="zone zone-2">
        <img src={"../../assets/images/zone-2.png"} className="base" alt="" />
      </div>
      <div ref={zone3} className="zone zone-3">
        <img src={"../../assets/images/zone-3.png"} className="base" alt="" />
      </div>
      <div ref={zone4} className="zone zone-4">
        <img src={"../../assets/images/zone-4.png"} className="base" alt="" />
      </div>
      <div ref={zone5} className="zone zone-5">
        <img src={"../../assets/images/zone-5.png"} className="base" alt="" />
      </div>
    </div>
  );
}

export default ZoneUI;
