import "./WonUI.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../../data/data";
import useStore from "../../../store";
import gsap from "gsap";
// import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";

function WonUI(props) {
  const setPlaySound = useStore((state) => state.setPlaySound);
  const appState = useStore((state) => state.appState);
  //const setAppState = useStore((state) => state.setAppState);
  const gameState = useStore((state) => state.gameState);
  const setGameState = useStore((state) => state.setGameState);
  const setShowInstructionsButton = useStore(
    (state) => state.setShowInstructionsButton
  );
  const showInstructionsButton = useStore(
    (state) => state.showInstructionsButton
  );

  const el = useRef();
  const card = useRef();
  const replayButton = useRef();
  const visitButton = useRef();
  const tl = useRef();

  useEffect(() => {
    if (gameState === "won") {
      if (tl.current) tl.current.pause();
      tl.current = gsap.timeline();
      console.log("winner");
      //show instrucitions
      tl.current.set(el.current, { autoAlpha: 1, zIndex: 2100 });
      tl.current.fromTo(
        [card.current],
        { y: 500, autoAlpha: 0 },
        {
          autoAlpha: 1,
          y: 0,
          duration: ".5",
          ease: "back.out",
        }
      );
    } else {
      //hide
      if (tl.current) tl.current.pause();
      tl.current = gsap.timeline();
      tl.current.to([card.current], {
        autoAlpha: 0,
        y: 500,
        ease: "back.in",
      });
      tl.current.set(el.current, { autoAlpha: 0, zIndex: -1 });
    }
  }, [gameState]);

  const replayClicked = () => {
    setPlaySound("click");
    window.location.reload();
  };

  const visitClicked = () => {
    setPlaySound("click");
    window.open("https://www.snacks.com/");
  };

  return (
    <div ref={el} className="won-ui">
      <div ref={card} className="card">
        <h1 className="title" data-text="Congratulations!">
          Congratulations!
        </h1>
        <div className="text">
          We received your information, so keep an eye out for an email that
          will provide you with further details. Good luck!
        </div>
        <button
          ref={visitButton}
          className="visit-button"
          onClick={visitClicked}
          id="won-visit-snacks-button"
        >
          <img src="../../assets/images/visit-snacks.png" alt="start" />
        </button>
        <button
          ref={replayButton}
          className="replay-button"
          onClick={replayClicked}
          id="won-replay-button"
        >
          <img src="../../assets/images/play-again.png" alt="start" />
        </button>
      </div>
    </div>
  );
}

export default WonUI;
