import "./LostUI.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../../data/data";
import useStore from "../../../store";
import gsap from "gsap";
// import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";

function LostUI(props) {
  const setPlaySound = useStore((state) => state.setPlaySound);
  const appState = useStore((state) => state.appState);
  //const setAppState = useStore((state) => state.setAppState);
  const gameState = useStore((state) => state.gameState);
  const setGameState = useStore((state) => state.setGameState);
  const setShowInstructionsButton = useStore(
    (state) => state.setShowInstructionsButton
  );
  const showInstructionsButton = useStore(
    (state) => state.showInstructionsButton
  );

  const el = useRef();
  const card = useRef();
  const replayButton = useRef();
  const visitButton = useRef();
  const tl = useRef();

  useEffect(() => {
    if (gameState === "lost") {
      if (tl.current) tl.current.pause();
      tl.current = gsap.timeline();
      //show instrucitions
      tl.current.set(el.current, { autoAlpha: 1, zIndex: 2100 });
      tl.current.fromTo(
        [card.current],
        { y: 500, autoAlpha: 0 },
        {
          autoAlpha: 1,
          y: 0,
          duration: ".5",
          ease: "back.out",
        }
      );
    }
    // } else {
    //   //hide the place board UI
    //   console.log("hide instrucitons");
    //   if (tl.current) tl.current.pause();
    //   tl.current = gsap.timeline();
    //   tl.current.to([card.current], {
    //     autoAlpha: 0,
    //     y: 500,
    //     ease: "back.in",
    //   });
    //   tl.current.set(el.current, { autoAlpha: 0, zIndex: -1 });
    // }
  }, [gameState]);

  const replayClicked = () => {
    setPlaySound("click");
    window.location.reload();
  };

  const visitClicked = () => {
    setPlaySound("click");
    window.open("https://www.snacks.com/");
  };

  return (
    <div ref={el} className="lost-ui">
      <div ref={card} className="card">
        <h1
          className="title"
          data-text="BETTER LUCK
NEXT TIME!"
        >
          BETTER LUCK NEXT TIME!
        </h1>
        <div className="text">
          Looks like you didn't win a Game Chest. Visit snacks.com for 10% off
          your order with code Hasbro10 or play the game again.
        </div>
        <button
          ref={visitButton}
          className="visit-button"
          onClick={visitClicked}
          id="lost-visit-snacks-button"
        >
          <img src="../../assets/images/visit-snacks.png" alt="start" />
        </button>
        <button
          ref={replayButton}
          className="replay-button"
          onClick={replayClicked}
          id="lost-replay-button"
        >
          <img src="../../assets/images/play-again.png" alt="start" />
        </button>
      </div>
    </div>
  );
}

export default LostUI;
