import "./ResultsUI.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../../data/data";
import useStore from "../../../store";
import gsap from "gsap";
// import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";

function ResultsUI(props) {
  const setPlaySound = useStore((state) => state.setPlaySound);
  const appState = useStore((state) => state.appState);
  //const setAppState = useStore((state) => state.setAppState);
  const gameState = useStore((state) => state.gameState);
  const setGameState = useStore((state) => state.setGameState);
  const showInstructions = useStore((state) => state.showInstructions);
  const setShowInstructions = useStore((state) => state.setShowInstructions);
  const setShowInstructionsButton = useStore(
    (state) => state.setShowInstructionsButton
  );
  const showInstructionsButton = useStore(
    (state) => state.showInstructionsButton
  );

  const snacksCollected = useStore((state) => state.snacksCollected);

  const el = useRef();
  const resultsCard = useRef();
  const tl = useRef();

  const claimButton = useRef();
  const replayButton = useRef();

  useEffect(() => {
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline();

    if (gameState === "end") {
      console.log("show results");
      //show instrucitions
      tl.current.set(el.current, { autoAlpha: 1, zIndex: 2100 });
      tl.current.fromTo(
        [resultsCard.current],
        { y: 500, autoAlpha: 0 },
        {
          autoAlpha: 1,
          y: 0,
          duration: ".5",
          ease: "back.out",
        }
      );
    } else {
      //hide the place board UI
      console.log("hide results");
      tl.current.to([resultsCard.current], {
        autoAlpha: 0,
        y: 500,
        ease: "back.in",
      });
      tl.current.set(el.current, { autoAlpha: 0, zIndex: -1 });
    }
  }, [gameState]);

  const claimClicked = () => {
    setPlaySound("click");
    //setShowInstructionsButton(false);
    setGameState("claim");
  };

  const replayClicked = () => {
    setPlaySound("click");
    window.location.reload();
  };

  return (
    <div ref={el} className="results-ui">
      <div ref={resultsCard} className="results-card">
        <img
          className="background"
          src={"../../assets/images/results-ui-card.png"}
          alt=""
        />
        <h1 className="title" data-text="Great Job!">
          Great Job!
        </h1>
        <div className="score-wrapper">
          <img
            className="icon"
            src={"../../assets/images/results-ui-icon.png"}
            alt=""
          />
          <div className="scrore-text-wrapper">
            <div className="text-1">{`${snacksCollected}`}</div>
            <div className="text-2">
              Snacks
              <br />
              Collected!
            </div>
          </div>
        </div>

        <button
          ref={claimButton}
          className="claim-button"
          onClick={claimClicked}
          id="results-claim-prize-button"
        >
          <img
            src="../../assets/images/results-ui-claim-button.png"
            alt="start"
          />
        </button>
        <button
          ref={replayButton}
          className="replay-button"
          onClick={replayClicked}
          id="results-replay-button"
        >
          Play Again
        </button>
      </div>
    </div>
  );
}

export default ResultsUI;
