import "./PermissionError.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../data/data";
import gsap from "gsap";
import useStore from "../../store";
// import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";

function PermissionError(props) {
  const appState = useStore((state) => state.appState);
  const showPermissionError = useStore((state) => state.showPermissionError);
  const setShowPermissionError = useStore(
    (state) => state.setShowPermissionError
  );

  const setPlaySound = useStore((state) => state.setPlaySound);

  const el = useRef();
  const tl = useRef();
  const background = useRef();
  const card = useRef();

  useEffect(() => {
    // intro
    if (tl.current) tl.current.pause();
    if (showPermissionError) {
      tl.current = gsap.timeline({ delay: 0.2 });
      tl.current.set(el.current, { autoAlpha: 1, zIndex: 2100 });
      tl.current.fromTo(
        [card.current],
        { autoAlpha: 1, y: 800 },
        { autoAlpha: 1, y: 0, duration: 0.7, ease: "back.out" }
      );
    } else {
      tl.current = gsap.timeline({});
      tl.current.to([card.current], {
        autoAlpha: 0,
        y: 800,
        duration: 0.5,
        ease: "back.in",
      });
      tl.current.set(el.current, { autoAlpha: 0, zIndex: -1 });
    }
  }, [showPermissionError]);

  const reloadClicked = () => {
    setPlaySound("click");
    setShowPermissionError(false);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  return (
    <div ref={el} className={`permission-error`}>
      <div ref={card} className="card">
        <h1 className="title">We couldn’t access your camera or orientation</h1>
        <div className="text-area">
          <p>
            Reload the page and
            <br />
            enable access.
          </p>
          <p>
            Please ensure that camera, motion and orientation access is enabled
            in app settings.
          </p>
        </div>
        <button
          // ref={startButton}
          className="reload-button"
          onClick={reloadClicked}
          id="restart-button"
        >
          <img src="../../assets/images/reload-button.png" alt="start" />
        </button>
      </div>
    </div>
  );
}

export default PermissionError;
