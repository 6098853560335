import "./Rules.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../data/data";
import gsap from "gsap";
import useStore from "../../store";
// import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";

function Rules(props) {
  const appState = useStore((state) => state.appState);
  const setAppState = useStore((state) => state.setAppState);
  const setIsPlacingBoard = useStore((state) => state.setIsPlacingBoard);
  const setIsARActive = useStore((state) => state.setIsARActive);
  const setShowAgeGate = useStore((state) => state.setShowAgeGate);
  const setPlaySound = useStore((state) => state.setPlaySound);

  const el = useRef();
  const tl = useRef();
  const background = useRef();
  const card = useRef();

  useEffect(() => {
    // intro
    if (tl.current) tl.current.pause();
    if (appState === "rules") {
      //setShowAgeGate(true);
      tl.current = gsap.timeline({ delay: 0.5 });
      tl.current.set(el.current, { autoAlpha: 1, zIndex: 1100 });

      tl.current.fromTo(
        background.current,
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 0.5 }
      );
      tl.current.fromTo(
        [card.current],
        { autoAlpha: 1, y: 800 },
        { autoAlpha: 1, y: 0, duration: 0.7, ease: "back.out" }
      );
    } else {
      tl.current = gsap.timeline({});
      tl.current.to([card.current], {
        autoAlpha: 0,
        y: 800,
        duration: 0.5,
        ease: "back.in",
      });
      tl.current.to(background.current, { autoAlpha: 0, duration: 0.5 });

      tl.current.set(el.current, { autoAlpha: 0, zIndex: -1 });
    }
  }, [appState]);

  const closeClicked = () => {
    setPlaySound("click");
    setAppState("landing");
  };

  return (
    <div ref={el} className={`rules`}>
      <img
        ref={background}
        className="background"
        src="../../assets/images/terms-background.jpg"
        alt=""
      />
      <div ref={card} className="card">
        <button onClick={closeClicked} className="close-button">
          <img src="../../assets/images/close.svg" alt="close" />
        </button>
        <h1 className="title">Game Rules</h1>
        <div className="text-area">
          <p className="p1"></p>
          <p className="p1">
            <span className="s1">
              <b>FRITO-LAY PLAY TOGETHER, WIN TOGETHER INSTANT WIN</b>
            </span>
          </p>
          <p className="p1">
            <span className="s1">
              <b>OFFICIAL RULES</b>
            </span>
          </p>
          <p className="p1">
            <span className="s1">
              <b> </b>
            </span>
          </p>
          <p className="p1">
            <span className="s1">
              <b>
                NO PURCHASE OR PAYMENT OF ANY KIND NECESSARY TO ENTER OR WIN. A
                PURCHASE DOES NOT INCREASE CHANCES OF WINNING. INTERNET ACCESS
                REQUIRED.
              </b>
            </span>
          </p>
          <p className="p2">
            <span className="s1">
              <b>
                BY SUBMITTING AN ENTRY TO THIS INSTANT WIN DURING THE INSTANT
                WIN PERIOD, YOU AGREE TO THESE OFFICIAL RULES, WHICH ARE A
                CONTRACT.  PLEASE READ THEM CAREFULLY BEFORE ENTERING.  
              </b>
            </span>
          </p>
          <p className="p3">
            <span className="s1"> </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b>ELIGIBILITY: </b>Open to legal residents of the fifty (50)
              United States and the District of Columbia, who are at least
              eighteen (18) years of age and the age of majority in their state
              of residence (at least nineteen (19) in Alabama and Nebraska) at
              the time of entry. The following individuals are not eligible to
              enter or win a Prize: officers, directors, employees,
              representatives and agents of{" "}
            </span>
            <span className="s2">Frito-Lay, Inc. </span>
            <span className="s1">
              (the “Sponsor”), their respective parents, subsidiaries and
              affiliates and if applicable, their advertising or promotion
              agencies (including Tracy-Locke. and Southern Made, Inc.), program
              prize suppliers, any other companies engaged in the development,
              production, or distribution of materials for the Instant Win (the
              “Instant Win Parties”), and members of their immediate families
              (spouse, parent, child, sibling, grandparent, and spouse or "step"
              of each) and those living in the same household of each such
              person (those persons whether related or not who live in the same
              residence for at least three months during the twelve-month period
              preceding the start date of the Instant Win).
            </span>
          </p>
          <p className="p4">
            <span className="s1"> </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b>INSTANT WIN PERIOD:</b> The Frito-Lay Play Together, Win
              Together Instant Win (“Instant Win”) begins 6:00 a.m. Eastern Time
              (“ET”) on Monday, March 25, 2024, and ends at 11:00 p.m. ET on
              Saturday, May 19, 2024 (the “Instant Win Period”). 
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              By participating, each participant (“Entrant”) agrees to be bound
              by these Official Rules and the decisions of the Sponsor, which
              are final and binding on matters relating to this Instant Win. The
              website server is the official clock for this Instant Win.  
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b> </b>
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b>HOW TO PARTICIPATE: </b>During the Instant Win Period, there is
              one (1) way to participate.
            </span>
          </p>
          <p className="p4">
            <span className="s3">
              <b> </b>
            </span>
          </p>
          <p className="p4">
            <span className="s3">
              <b>1) Game Play with Scan</b>
            </span>
            <span className="s1">
              <b>:</b>
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b>1.</b>
            </span>
            <span className="s4"> </span>
            <span className="s1">
              Entrant to scan QR Code inside the participating product
              (“Participating Product”) or visit{" "}
              <a href="https://www.fritolayvarietypacks.com/hasbro">
                <span className="s5">
                  https://www.fritolayvarietypacks.com/hasbro
                </span>
              </a>{" "}
              (“Website”).
            </span>
            <span className="s4">  </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b>2.</b>Entrant will then click the button to launch the game and
              grant motion and orientation access and then camera access on
              their mobile or tablet device. 
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b>3. </b>Follow the prompts to place Participating Product in
              frame on their mobile or tablet device to reveal the virtual game
              board.  
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b>4. </b>Entrant will be instructed to tap the dice button to
              roll the dice.  The dice will show a number and the game piece
              will move accordingly on the virtual game board.  Entrant can
              select the question mark “?” to view game instructions at any time
              during the game. 
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b>5.</b>  Entrant will continue to roll the dice until the
              Entrant crosses the finish line.   
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b>6.</b> Once Entrant crosses the finish line, they will obtain
              one (1) game play (“Game Play”) and will then receive a winning or
              non-winning notification on-screen.  If Entrant is a potential
              instant winner (“Instant Winner”), they would also see on-screen a
              message to enter name and email to claim the prize and see prize
              details.  Entrant will be sent an email stating the prize won
              <b> </b>as well.
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b>7. </b>Entrant can elect to bypass the game to obtain one (1)
              Game Play.  <b> </b>
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b>8</b>. If you receive a non-winning notification on-screen you
              will receive a message that you can play again the next day.
              <b> </b>
            </span>
          </p>
          <p className="p4">
            <span className="s1"> </span>
          </p>
          <p className="p4">
            <span className="s1">Participating Products include:</span>
          </p>
          <ul className="ul1">
            <li className="li5">
              <span className="s6"></span>
              <span className="s2">
                Frito-Lay Munchies® Peanut Butter on Toast Crackers – 8 count
              </span>
            </li>
          </ul>
          <ul className="ul1">
            <li className="li5">
              <span className="s6"></span>
              <span className="s2">
                Frito-Lay Munchies Cheetos® Flavored Cheddar Cheese on Toast
                Crackers – 8 count
              </span>
            </li>
          </ul>
          <ul className="ul1">
            <li className="li5">
              <span className="s6"></span>
              <span className="s2">Frito-Lay Variety Packs:</span>
            </li>
          </ul>
          <ul className="ul1">
            <ul className="ul2">
              <li className="li5">
                <span className="s6"></span>
                <span className="s2">Classic Mix 18 count</span>
              </li>
            </ul>
          </ul>
          <ul className="ul1">
            <ul className="ul2">
              <li className="li5">
                <span className="s6"></span>
                <span className="s2">Fiery Mix 18 count</span>
              </li>
            </ul>
          </ul>
          <ul className="ul1">
            <ul className="ul2">
              <li className="li5">
                <span className="s6"></span>
                <span className="s2">Flavor Mix 18 count</span>
              </li>
            </ul>
          </ul>
          <ul className="ul1">
            <ul className="ul2">
              <li className="li5">
                <span className="s6"></span>
                <span className="s2">Family Fun Mix 18 count</span>
              </li>
            </ul>
          </ul>
          <p className="p6">
            <span className="s1"></span>
            <br />
          </p>
          <p className="p4">
            <span className="s1">Entry Considerations:</span>
          </p>
          <ul className="ul1">
            <li className="li4">
              <span className="s7"></span>
              <span className="s1">
                Prior to participating, each Entrant must verify their email by
                clicking on an email link sent after registration is complete.  
              </span>
            </li>
          </ul>
          <ul className="ul1">
            <li className="li4">
              <span className="s7"></span>
              <span className="s1">
                If you scan a non-participating product, you will receive an
                error message and be prompted to try again with a participating
                product.  Not all products and package sizes are available at
                all retailers.
              </span>
            </li>
          </ul>
          <p className="p4">
            <span className="s1">  </span>
          </p>
          <p className="p4">
            <span className="s1">
              All Game Plays shall be deemed to be submitted by the authorized
              account holder of the email address used to register for the
              Instant Win. “Authorized Account Holder” of an email is the
              natural person who is assigned to an email address by an Internet
              access or online service provider, or other organization
              responsible for assigning email addresses for the domain
              associated with the submitted email address. You may only
              participate in the Instant Win using an email address for which
              you are the Authorized Account Holder. By submitting an email
              address to enter the Instant Win, you represent and warrant that
              you are the Authorized Account Holder. If a dispute arises
              regarding who submitted an online entry, the Game Play will be
              deemed to be submitted by the Authorized Account Holder of the
              email address provided at time of registration. Standard data
              rates may apply if the Website is accessed via a mobile device,
              and Entrants are solely responsible for any such charges.
            </span>
          </p>
          <p className="p6">
            <span className="s1"></span>
            <br />
          </p>
          <p className="p4">
            <span className="s1">
              For purposes of the Instant Win Game, an entrant’s residential
              address and e-mail address will be the physical address and e-mail
              address submitted at the time of entry. Entrants will not be
              allowed to change their physical address or e-mail address. If it
              is discovered or suspected that an entrant has registered or
              attempted to register for the Instant Win using multiple e-mail
              addresses or multiple identities, all of that entrant’s Game Plays
              (as defined above) associated with that household (determined by
              Sponsor and/or Administrator in its sole discretion) may be
              disqualified and any prize an entrant might have been entitled to
              win will not be awarded and may be revoked. Use of any automated
              system or any like methods to participate in the Instant Win is
              prohibited and will result in disqualification of any entrant
              attempting such use.
            </span>
          </p>
          <p className="p4">
            <span className="s1"> </span>
          </p>
          <p className="p4">
            <span className="s1">
              Entrants using any other device or artifice to enter multiple
              times above the stated limit or as multiple entrants will be
              disqualified. Game Plays generated by script, macro, robotic,
              programmed, or any other automated means are prohibited and will
              be disqualified. Any incomplete, misdirected or postage due
              mail-in requests will be considered ineligible. Proof of{" "}
              <i>submission is not proof </i>of<i> </i>receipt by Sponsor. By
              participating, Entrants agree to abide by and be bound by these
              Official Rules and the Sponsor’s decisions.
            </span>
          </p>
          <p className="p4">
            <span className="s1"> </span>
          </p>
          <p className="p4">
            <span className="s1">
              Game Plays are void if (i) not obtained or submitted in accordance
              with these Official Rules; or (ii) are obtained as the result of
              or in connection with a sale, transfer, or other method which we
              determine, in Sponsor’s sole discretion, is in violation of these
              Official Rules. 
            </span>
          </p>
          <p className="p4">
            <span className="s1"> </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b>INSTANT WIN NOTIFICATION:</b> 
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              If you are the first to submit a Game Play at a randomly selected
              winning moment, as determined by Administrator, or if no one
              submits a Game Play at such moment, and you are the first to play
              immediately after such moment, as determined and verified by
              Administrator, you will be deemed a potential Instant Winner.  You
              will then receive a winning or non-winning notification
              on-screen.  If you are a potential Instant Winner, you would also
              see on-screen details on the Prize you have won, and you will be
              sent an email stating the Prize won.  
            </span>
          </p>
          <p className="p6">
            <span className="s1"></span>
            <br />
          </p>
          <p className="p4">
            <span className="s1">
              Photos of winner messages on the screen is not acceptable as proof
              of winning. If Prize notification or email is returned as
              undeliverable or if a potential Instant Winner is not in
              compliance with these Official Rules, Prize will be forfeited, and
              Sponsor will have no further obligation to such Instant Winner.
              The Sponsor reserves the right not to award a Prize in the event
              the Sponsor is unable to identify and confirm an Instant Winner.
            </span>
          </p>
          <p className="p6">
            <span className="s1"></span>
            <br />
          </p>
          <p className="p4">
            <span className="s1">
              <b>Limit one (1) Prize per household.  </b>
            </span>
          </p>
          <p className="p6">
            <span className="s1"></span>
            <br />
          </p>
          <p className="p4">
            <span className="s1">
              <b>
                PRIZES (the “Prize(s)”) AND APPROXIMATE RETAIL VALUE (“ARV”):
              </b>
            </span>
          </p>
          <p className="p4">
            <span className="s1"> </span>
          </p>
          <p className="p4">
            <span className="s1">
              There are fifty-six (56) Prizes available to be awarded.  Each
              Instant Winner will receive a Game Chest that includes the
              following:
            </span>
          </p>
          <p className="p6">
            <span className="s1"></span>
            <br />
          </p>
          <ul className="ul1">
            <li className="li4">
              <span className="s7"></span>
              <span className="s1">Game Chest Trunk (ARV $500)</span>
            </li>
          </ul>
          <ul className="ul1">
            <li className="li4">
              <span className="s7"></span>
              <span className="s1">A Set of 4 Custom Tumblers (ARV $200)</span>
            </li>
          </ul>
          <ul className="ul1">
            <li className="li4">
              <span className="s7"></span>
              <span className="s1">
                Acrylic Custom 11x16 Scoreboard (ARV $40)
              </span>
            </li>
          </ul>
          <ul className="ul1">
            <li className="li4">
              <span className="s7"></span>
              <span className="s1">Speaker (ARV $135)</span>
            </li>
          </ul>
          <ul className="ul1">
            <li className="li4">
              <span className="s7"></span>
              <span className="s1">
                1 Set of Custom Monopoly Pieces (ARV $100)
              </span>
            </li>
          </ul>
          <ul className="ul1">
            <li className="li4">
              <span className="s7"></span>
              <span className="s1">
                Hasbro Game Bundle which includes Game of Life (ARV $20),
                Connect 4 (ARV $10), Monopoly ($20), Clue (ARV $20), Battleship
                ($16), Scrabble (ARV $20), Sorry (ARV $10), and Twister (ARV
                $20), Monopoly Knockout (ARV $20), Monopoly Deal (ARV $5). Total
                ARV for Hasbro Game Bundle is $161.
              </span>
            </li>
          </ul>
          <ul className="ul1">
            <li className="li4">
              <span className="s7"></span>
              <span className="s1">Blanket (ARV $60)</span>
            </li>
          </ul>
          <ul className="ul1">
            <li className="li4">
              <span className="s7"></span>
              <span className="s1">Chips (ARV $5)</span>
            </li>
          </ul>
          <p className="p6">
            <span className="s1"></span>
            <br />
          </p>
          <p className="p7">
            <span className="s8">When </span>
            <span className="s1">
              an Entrant has won a Prize, they will be notified via a
              confirmation email. This confirmation email message will detail
              what the Prize is, details on shipment, and a contact number or
              email for further questions.  
            </span>
          </p>
          <p className="p6">
            <span className="s1"></span>
            <br />
          </p>
          <p className="p4">
            <span className="s1">
              <b>
                The ARV of each Instant Win Prize is $1,191.00. The ARV of all
                Instant Win Prizes is $56,336.00.
              </b>
            </span>
          </p>
          <p className="p8">
            <span className="s1"> </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b>PRIZE TERMS AND CONDITIONS:</b> Prizes consist only of the
              elements expressly set forth above; no other elements are included
              in the Prize and any such expenses in connection with the use of
              the Prize are the sole responsibility of each Instant Winner.
              Instant Winners are responsible for all applicable federal, state,
              local sales and income taxes and any costs, expense(s), or fees
              whatsoever in connection with the Prize not specifically provided
              herein. In the event that, for reasons beyond their control and
              not related to the Winner, the Sponsor is unable to award any
              Prize as described in these Official Rules (including if the
              designated Prize should become unavailable for any reason), the
              Sponsor may substitute the Prize with another Prize of similar
              nature and equal or greater value at its sole discretion. The
              Prize is non-assignable and non-transferable and is not redeemable
              for cash and no substitution will be made except as provided
              herein at the Sponsor’s sole discretion. Any difference between
              the ARV of any Prize stated and the actual value of the Prize will
              not be awarded. If a selected Winner is unable to accept a Prize
              in its entirety for any reason, the Prize (or that portion of the
              Prize) may be forfeited. All Game Plays and potential Instant
              Winners are subject to verification by the Sponsor and the
              Administrator whose decisions are final and binding. No
              compensation will be paid in lieu of a Prize or in the event that
              the Prize has been forfeited for any reason stated in these
              Official Rules. Sponsor and Instant Win Parties shall not be held
              responsible for any delays in awarding any Prize for any reason.
              By participating in this Instant Win or submitting an entry, each
              Entrant agrees: (i) to be bound by these Official Rules, including
              all entry requirements, (ii) to allow Sponsor to send program
              specific emails, and (iii) to release and hold the Sponsor and
              Instant Win Parties harmless from and against any and all claims,
              injuries, damages, losses and liability that may occur, directly
              or indirectly, in whole or in part, from the participation in the
              Instant Win or from the receipt or use of any Prize or activity
              related to the receipt or use of any Prize. In the event there is
              a discrepancy or inconsistency between disclosures or other
              statements contained in any Instant Win materials and the terms
              and conditions of the Official Rules, the Official Rules shall
              prevail, govern, and control.
            </span>
          </p>
          <p className="p6">
            <span className="s1"></span>
            <br />
          </p>
          <p className="p4">
            <span className="s1">
              Prizes will be mailed to the mailing address (no P.O. Boxes), or
              email provided at registration. Shipping in the 50 United States
              and DC only. Shipping is included.  Physical Prizes may take 10-12
              weeks to arrive. Sponsor makes no representations or warranties of
              any kind concerning the appearance, safety, or performance of any
              prize. Winner shall bear all risk of loss or damage to prize after
              it has been delivered.  
            </span>
          </p>
          <p className="p6">
            <span className="s1"></span>
            <br />
          </p>
          <p className="p4">
            <span className="s1">
              <b>PRIZE CLAIM: </b>Instant Winners are subject to verification.
              The potential Instant Winners will be notified via email using the
              email address supplied during registration. The potential Instant
              Winners will have three (3) business days to respond and return
              email with their acceptance of the Prize. Upon the acceptance, the
              potential Instant Winners will be sent winner documents via a
              DocuSign email and will be required to verify his/her/their
              eligibility by completing, signing, and returning an Affidavit of
              Eligibility/Liability Release, and, where lawful, a Publicity
              Release, and a completed and signed Federal Form W-9, by email by
              the date indicated on the documents or Prize may be forfeited and
              an alternate Instant Winner may be contacted. Failure to provide
              all required information, and a signature on the documents will
              result in forfeiture of the Instant Winner’s right to claim a
              Prize and may be awarded to an alternate Instant Winner.
            </span>
          </p>
          <p className="p4">
            <span className="s1"> </span>
          </p>
          <p className="p4">
            <span className="s1">
              All costs and expenses associated with a Prize acceptance and use
              not specifically provided herein are the responsibility of the
              Instant Winners. Any Prize will be forfeited, and an alternate
              potential Instant Winner may be contacted if: (1) a Prize
              notification is returned as undeliverable; (2) if any required
              response (including email response) is not received in three (3)
              business days for any reason; (3) if any Prize claim forms or
              Instant Winner verification materials are not returned within the
              required time period; (4) if a potential Instant Winner is found
              to be ineligible by civil or criminal or social media information
              review, as determined by Sponsor in its sole discretion; or (5) if
              any potential Instant Winner is determined to be ineligible or
              otherwise not in compliance with these Official Rules. Sponsor and
              Instant Win Parties are not responsible for any change of mailing
              address, email address, and/or telephone number of Entrants.
              Sponsor is not required to make more than one attempt to notify
              any individual potential Instant Winner; nor is Sponsor required
              to make more than one attempt to verify an alternative Instant
              Winner if the first selected potential Instant Winner is
              disqualified. If an insufficient number of qualified Entries are
              received, Prizes may not be awarded. Prizes that are unclaimed,
              unredeemed, or returned as undeliverable will not be awarded.
            </span>
          </p>
          <p className="p6">
            <span className="s1"></span>
            <br />
          </p>
          <p className="p4">
            <span className="s3">Taxes:</span>
            <span className="s1">
              {" "}
              Instant Winners are solely responsible for any applicable federal,
              state, and local taxes on the Prize and will be issued an IRS Form
              1099 in January of 2025 reporting the value of any Prize valued
              $600 or over. Instant Winners are responsible for paying taxes on
              the full ARV of the Prize awarded to them<b>. </b>
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b> </b>
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b>GENERAL CONDITIONS:</b> INTERNET CAUTION: ANY ATTEMPT BY AN
              INDIVIDUAL TO DELIBERATELY DAMAGE ANY WEBSITE OR UNDERMINE THE
              LEGITIMATE OPERATION OF THIS INSTANT WIN IS A VIOLATION OF
              CRIMINAL AND CIVIL LAWS, AND SHOULD SUCH AN ATTEMPT BE MADE,
              SPONSOR RESERVES THE RIGHT TO SEEK DAMAGES FROM ANY SUCH
              INDIVIDUAL TO THE FULLEST EXTENT PERMITTED BY LAW INCLUDING
              CRIMINAL PROSECUTION. If the Instant Win is not capable of running
              as planned for any reason, including without limitation, due to
              infection by computer virus, bugs, tampering, unauthorized
              intervention, fraud, technical failures, or any other causes which
              corrupt or affect the administration, security, fairness,
              integrity, or proper conduct of the Instant Win, Sponsor reserves
              the right, in its sole discretion, to disqualify any individual
              who is responsible or who tampers with the entry process, and to
              cancel, modify, or terminate the Instant Win. In Sponsor’s sole
              discretion, any portion of the Instant Win is not capable of
              running as planned for any reason, or the integrity and/or
              feasibility of the Instant Win is severely undermined by any event
              beyond the control of Sponsor, including but not limited to fire,
              flood, health pandemic or epidemic, earthquake, explosion, extreme
              weather conditions, interruptions in air travel, labor dispute or
              strike, act of God or public enemy, satellite or equipment
              failure, riot or civil disturbance, war (declared or undeclared),
              terrorist threat or activity, or any federal, state, or local
              government law, order, or regulation, order of any court or
              jurisdiction, infection by computer virus, unauthorized
              intervention, technical failures or other cause not reasonably
              within the control of Sponsor (each a “Force Majeure” event or
              occurrence), Sponsor shall have the right, in its sole discretion,
              to abbreviate, modify, suspend, cancel or terminate the Instant
              Win without any further obligation. In the event of cancellation,
              Sponsor will award the Prize(s) for the Instant Win Drawing(s) at
              issue in random drawing(s) from among all valid and eligible
              entries received for the applicable Drawing(s) up to the time of
              such cancellation, provided a sufficient number of entries are
              received. In no event will more than the number of Prizes stated
              in these Official Rules be awarded. In the event that an entry is
              confirmed to have been erroneously deleted, lost, or destroyed,
              the Entrant’s sole remedy shall be another entry into the Instant
              Win.
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b> </b>
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b>LIMITATIONS OF LIABILITY AND RELEASE:</b> As a condition of
              entering, Entrants agree to release the Sponsor, and Instant Win
              Parties, (collectively, “Releasees”) from any and all liability,
              loss, or damage incurred with respect to Entrant’s participation
              in the Instant Win and the awarding, receipt, possession, and/or
              use or misuse of any Prize. No liability or responsibility is
              assumed by the Releasees resulting from user's participation in,
              attempt to participate in, or download of any information in
              connection with participating in the Instant Win. Sponsor is not
              responsible for incorrect or inaccurate entry information whether
              caused by Entrant or by any of the equipment or programming
              associated with or utilized in the Instant Win, or by any
              technical or human error which may occur in the processing of the
              entries. No responsibility or liability is assumed by the
              Releasees for technical problems or technical malfunction,
              including, without limitation, those arising in connection with
              any of the following occurrences that may affect the operation of
              the Instant Win: hardware or software errors; faulty computer,
              telephone, cable, satellite, network, electronic, wireless, or
              Internet connectivity, or other online communication problems;
              errors or limitations of any Internet service providers, servers,
              hosts, or providers; garbled, jumbled, or faulty data
              transmissions; failure of any email transmissions to be sent to or
              received; lost, late, delayed, or intercepted email transmissions;
              inaccessibility of any Website or Application in whole or in part
              for any reason; traffic congestion on the Internet or any Website
              or Application; unauthorized human or non-human intervention in
              the operation of the Instant Win, including without limitation,
              unauthorized tampering, hacking, theft, virus, bug, worm; or
              destruction of any aspect of the Instant Win. Sponsor is not
              responsible for any typographical errors in the announcement of
              the Prize or these Official Rules, or any inaccurate or incorrect
              data contained on promotional materials or on the Website or App.
              Use of any Website or Application is at user’s own risk. Releasees
              are not responsible for any personal injury or property damage or
              any other losses of any kind that may be sustained to user's or
              any other person's computer equipment resulting from participation
              in the Instant Win, use of any Website or Application or the
              download of any information from a website or any other loss
              related to user’s participation in the Instant Win or receipt of
              any Prize. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR
              EXCLUSION OF LIABILITY FOR INCIDENTAL, CONSEQUENTIAL, OR OTHER
              DAMAGES; AS A RESULT, THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT
              APPLY TO YOU, AND THE FOREGOING PARAGRAPHS SHALL NOT APPLY TO A
              RESIDENT OF NEW JERSEY TO THE EXTENT DAMAGES TO SUCH NEW JERSEY
              RESIDENT ARE THE RESULT OF SPONSOR OR INSTANT WIN PARTIES’
              NEGLIGENT, FRAUDULENT OR RECKLESS ACT(S) OR INTENTIONAL
              MISCONDUCT.
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b> </b>
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b>BINDING ARBITRATION:</b>  The parties waive all rights to trial
              in any action or proceeding instituted in connection with these
              Rules, including, without limitation, the Instant Win. The parties
              each agree to finally settle all disputes only through
              arbitration; provided, however, the Sponsor shall be entitled to
              seek injunctive or equitable relief in the state and federal
              courts in Texas, and any other court with jurisdiction over the
              parties. In arbitration, there is no judge or jury and review is
              limited. The arbitrator’s decision and award is final and binding,
              with limited exceptions, and judgment on the award may be entered
              in any court with jurisdiction. The parties agree that, except as
              set forth above, any claim, suit, action or proceeding arising out
              of or relating to this Instant Win shall be resolved solely by
              binding arbitration before a sole arbitrator under the streamlined
              Arbitration Rules Procedures of JAMS Inc. (“JAMS”) or any
              successor to JAMS.  In the event JAMS is unwilling or unable to
              set a hearing date within fourteen (14) days of the filing of a
              “Demand for Arbitration”, then either party can elect to have the
              arbitration administered by the American Arbitration Association
              (“AAA”) or any other mutually agreeable arbitration administration
              service. If an in-person hearing is required, then it will take
              place in Texas. The federal or state law that applies to these
              Official Rules will also apply during the arbitration. Disputes
              will be arbitrated only on an individual basis and will not be
              consolidated with any other proceedings that involve any claims or
              controversy of another party, including any class actions;
              provided, however, if for any reason any court or arbitrator holds
              that this restriction is unconscionable or unenforceable, then the
              agreement to arbitrate does not apply and the dispute must be
              brought in a court of competent jurisdiction in Texas. Sponsor
              agrees to pay the administrative and arbitrator’s fees in order to
              conduct the arbitration (but specifically excluding any travel or
              other costs of entrant to attend the arbitration hearing).  Either
              party may, notwithstanding this provision, bring qualifying claims
              in small claims court. This Instant Win is offered only in the
              United States and is governed by the laws of the state of Texas,
              and all claims must be resolved in the federal or state courts
              located in Collin or Dallas counties, Texas.{" "}
              <b>
                THE ARBITRATION OF DISPUTES PURSUANT TO THIS PARAGRAPH SHALL BE
                IN THE ENTRANT’S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
                CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
                PROCEEDING. THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN THE
                CLAIMS OF OTHER PERSONS OR PARTIES WHO MAY BE SIMILARLY
                SITUATED. DO NOT ENTER THIS INSTANT WIN IF YOU DO NOT AGREE TO
                HAVE ANY CLAIM OR CONTROVERSY ARBITRATED IN ACCORDANCE WITH
                THESE OFFICIAL RULES.
              </b>
            </span>
          </p>
          <p className="p4">
            <span className="s1"> </span>
          </p>
          <p className="p4">
            <span className="s1">
              BY PARTICIPATING IN THIS INSTANT WIN, EACH ENTRANT AGREES THAT TO
              THE EXTENT PERMITTED BY APPLICABLE LAW: (1) ANY AND ALL DISPUTES,
              CLAIMS, AND CAUSES OF ACTION ARISING OUT OF OR CONNECTED WITH THE
              INSTANT WIN, OR ANY PRIZE AWARDED, WILL BE RESOLVED INDIVIDUALLY
              THROUGH BINDING ARBITRATION AS SET FORTH ABOVE, WITHOUT RESORT TO
              ANY FORM OF CLASS ACTION; (2) ANY AND ALL CLAIMS, JUDGMENTS, AND
              AWARDS WILL BE LIMITED TO ACTUAL THIRD-PARTY, OUT-OF-POCKET COSTS
              INCURRED (IF ANY), BUT IN NO EVENT WILL ATTORNEYS' FEES BE AWARDED
              OR RECOVERABLE; (3) UNDER NO CIRCUMSTANCES WILL ANY ENTRANT BE
              PERMITTED TO OBTAIN ANY AWARD FOR, AND ENTRANT HEREBY KNOWINGLY
              AND EXPRESSLY WAIVES ALL RIGHTS TO SEEK, PUNITIVE, INCIDENTAL,
              CONSEQUENTIAL, OR SPECIAL DAMAGES, LOST PROFITS AND/OR ANY OTHER
              DAMAGES, OTHER THAN ACTUAL OUT OF POCKET EXPENSES, AND/OR ANY
              RIGHTS TO HAVE DAMAGES MULTIPLIED OR OTHERWISE INCREASED; AND (4)
              ENTRANT'S REMEDIES ARE LIMITED TO A CLAIM FOR MONEY DAMAGES (IF
              ANY) AND ENTRANT IRREVOCABLY WAIVES ANY RIGHT TO SEEK INJUNCTIVE
              OR EQUITABLE RELIEF. SOME JURISDICTIONS DO NOT ALLOW THE
              LIMITATIONS OR EXCLUSION OF LIABILITY, SO THE ABOVE MAY NOT APPLY
              TO EVERY ENTRANT.
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b> </b>
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b>PUBLICITY:</b> Submission of an entry, including any Prize
              notification documents (“Entrant Data”), constitutes your consent
              for Sponsor to obtain, use, and transfer your Entrant Data for
              Instant Win administration purposes and shall be subject to 
            </span>
          </p>
          <p className="p9">
            <span className="s8">
              the Frito Lay Privacy Policy available at
              <a href="https://www.pepsico.com/legal/privacy">
                <span className="s9"> </span>
              </a>
               
              <a href="https://contact.pepsico.com/fritolay/privacy-policy">
                <span className="s10">
                  https://contact.pepsico.com/fritolay/privacy-policy
                </span>
              </a>
              . 
            </span>
          </p>
          <p className="p6">
            <span className="s1"></span>
            <br />
          </p>
          <p className="p4">
            <span className="s1">
              Winner must consent (and confirm such consent in writing upon
              request) to the use of his/her name, voice, picture or other
              likeness without compensation for promotional purposes in all
              media as determined by Sponsor, except for residents of TN and
              where prohibited by law. In the event there is a discrepancy or
              inconsistency between disclosures and other statements contained
              in any Instant Win materials and the terms and conditions of these
              Official Rules, these Official Rules shall prevail, govern, and
              control.
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b> </b>
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b>GOVERNING LAW &amp; JURISDICTION:</b> All issues and questions
              concerning the construction, validity, interpretation, and
              enforceability of these official rules, Entrant’s rights and
              obligations, or the rights and obligations of the Sponsor in
              connection with the Instant Win, shall be governed by, and
              construed in accordance with, the laws of the State of Texas,
              without giving effect to any choice of law or conflict of law
              rules thereof. By entering, Entrants consent to the jurisdiction
              and venue of the federal, state, and local courts located in
              Collin or Dallas counties, Texas for the resolution of all matters
              or proceedings which are not subject to arbitration as set forth
              in the Binding Arbitration clause of these Official Rules and
              agree that any and all such disputes shall be resolved exclusively
              in those courts. Sponsor’s failure to enforce any term of these
              Official Rules shall not constitute a waiver of that or any other
              provision. If any provision of these Official Rules is held to be
              invalid or unenforceable, such provision shall be struck, and the
              remaining provisions shall be enforced.
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b> </b>
            </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b>CUSTOMER SERVICE AND INSTANT WINNERS’ LIST REQUEST</b>: For a
              list of Instant Winners, interested individuals should mail a
              self-addressed stamped business envelope to: Winners List, The
              Frito-Lay Play Together, Win Together Instant Win, P.O. Box
              251328, West Bloomfield, MI 48325. Winner List requests must be
              received no later than July 15, 2024.  
            </span>
          </p>
          <p className="p6">
            <span className="s1"></span>
            <br />
          </p>
          <p className="p4">
            <span className="s1">For questions on the Instant Win, email</span>
            <span className="s11"> </span>
            <span className="s12">
               
              <a href="mailto:support@pepsipromotionsupport.com">
                <span className="s13">support@pepsipromotionsupport.com</span>
              </a>
            </span>
            <span className="s14">
              <i> </i>
            </span>
            <span className="s1">no later than July 15, 2024.</span>
          </p>
          <p className="p4">
            <span className="s1"> </span>
          </p>
          <p className="p4">
            <span className="s1">
              <b>SPONSOR:</b> Frito-Lay, Inc. 7701 Legacy Drive, Plano, Texas
              75024.
            </span>
          </p>
          <p className="p6">
            <span className="s1"></span>
            <br />
          </p>
          <p className="p4">
            <span className="s1">
              <b>ADMINISTRATOR: </b>Southern Made Services, LLC, 844 Hillwood
              Blvd., Nashville, TN 37209.
            </span>
          </p>
          <p className="p6">
            <span className="s1"></span>
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}

export default Rules;
