import "./InstructionButton.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../../data/data";
import useStore from "../../../store";
import gsap from "gsap";
// import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";

function InstructionButton(props) {
  const setPlaySound = useStore((state) => state.setPlaySound);
  const appState = useStore((state) => state.appState);
  //const setAppState = useStore((state) => state.setAppState);
  const gameState = useStore((state) => state.gameState);
  const setGameState = useStore((state) => state.setGameState);
  const showInstructions = useStore((state) => state.showInstructions);
  const setShowInstructions = useStore((state) => state.setShowInstructions);
  const currentZone = useStore((state) => state.currentZone);
  const setShowInstructionsButton = useStore(
    (state) => state.setShowInstructionsButton
  );
  const showInstructionsButton = useStore(
    (state) => state.showInstructionsButton
  );
  const el = useRef();
  const tl = useRef();

  const showInstructionsClicked = () => {
    //setShowInstructionsButton(false);
    setPlaySound("click");
    setShowInstructions(!showInstructions);
  };

  useEffect(() => {
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline();
    if (showInstructionsButton) {
      // show the instrucitons icon
      tl.current.fromTo(
        [el.current],
        { y: 100, autoAlpha: 0 },
        {
          autoAlpha: 1,
          y: 0,
          duration: 0.5,
          ease: "back.out",
        }
      );
    } else {
      tl.current.to([el.current], {
        autoAlpha: 0,
        y: 100,
        duration: 0.5,
        ease: "back.in",
      });
    }
  }, [showInstructionsButton]);

  return (
    <button
      onClick={showInstructionsClicked}
      ref={el}
      className="instructions-button"
      id="instructions-button"
    >
      <img src="../../assets/images/instructions-icon.png" alt="instructions" />
    </button>
  );
}

export default InstructionButton;
