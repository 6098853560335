import "./StartUI.scss";
import { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import { data } from "../../../data/data";
import useStore from "../../../store";
import gsap from "gsap";
// import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";
import ImageSeqeuncePlayer from "../../ImageSeqeuncePlayer/ImageSeqeuncePlayer";

function StartUI(props) {
  const setPlaySound = useStore((state) => state.setPlaySound);
  const appState = useStore((state) => state.appState);
  //const setAppState = useStore((state) => state.setAppState);
  const gameState = useStore((state) => state.gameState);
  const setGameState = useStore((state) => state.setGameState);
  const el = useRef();
  const ani = useRef();
  const card = useRef();
  const tl = useRef();
  const bottomImage = useRef();

  const [images, setImages] = useState();
  const [paused, setPaused] = useState(true);

  useEffect(() => {
    let _images = [];

    for (let i = 0; i < 30; i++) {
      _images.push({
        filename:
          "../../assets/images/start-ui/LETS-PLAY" +
          (i < 10 ? "0" + i : i) +
          ".png",
      });
    }

    setImages(_images);
    // console.log(_images);
  }, []);

  useEffect(() => {
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline();
    if (gameState === "starting") {
      //show starting title
      tl.current.set(el.current, { zIndex: 2100 });
      tl.current.fromTo(
        [el.current, card.current],
        { y: 500, scale: 0.5, autoAlpha: 0 },
        {
          stagger: 0.5,
          autoAlpha: 1,
          y: 0,
          scale: 1,
          duration: 0.5,
          ease: "back.out",
        }
      );
      tl.current.call(
        () => {
          setPaused(false);
        },
        null,
        0.1
      );
      tl.current.call(
        () => {
          setGameState("waitingToRoll");
        },
        null,
        6
      );
    } else {
      // hide
      //show starting title
      tl.current.to([el.current, card.current], {
        y: 500,
        scale: 0.9,
        autoAlpha: 0,
        duration: 0.5,
        ease: "back.in",
      });
      tl.current.set(el.current, { autoAlpha: 0, zIndex: -1 });
      tl.current.call(() => {
        setPaused(true);
      });
    }
  }, [gameState]);

  return (
    <div
      ref={el}
      className="start-ui"
      onClick={() => {
        setGameState("waitingToRoll");
      }}
      id="start-ui-card"
    >
      {images && (
        <div ref={ani} className="animation-container">
          <ImageSeqeuncePlayer
            frameRate={18}
            images={images}
            width={640}
            height={640}
            paused={paused}
            loop={false}
          />
        </div>
      )}
      <div ref={card} className="start-ui-card">
        Find hidden Frito-Lay snacks along a delicious interactive journey for a
        chance to win a $1,000 Game Chest!
        <img
          className="start-ui-bottom"
          ref={bottomImage}
          src="../../assets/images/start-ui-bottom-image.png"
          alt=""
        />
      </div>
    </div>
  );
}

export default StartUI;
