import "./ClaimUI.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../../data/data";
import useStore from "../../../store";
import gsap from "gsap";
// import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";

function ClaimUI(props) {
  const setPlaySound = useStore((state) => state.setPlaySound);

  const appState = useStore((state) => state.appState);
  //const setAppState = useStore((state) => state.setAppState);
  const gameState = useStore((state) => state.gameState);
  const setGameState = useStore((state) => state.setGameState);
  const showInstructions = useStore((state) => state.showInstructions);
  const setShowInstructions = useStore((state) => state.setShowInstructions);
  const setShowInstructionsButton = useStore(
    (state) => state.setShowInstructionsButton
  );
  const showInstructionsButton = useStore(
    (state) => state.showInstructionsButton
  );

  const el = useRef();
  const resultsCard = useRef();
  const tl = useRef();

  useEffect(() => {
    if (gameState === "claim") {
      if (tl.current) tl.current.pause();
      tl.current = gsap.timeline({ delay: 0.5 });
      console.log("show results");
      //show instrucitions
      tl.current.set(el.current, { autoAlpha: 1, zIndex: 2100 });
      tl.current.fromTo(
        [resultsCard.current],
        { y: 500, autoAlpha: 0 },
        {
          autoAlpha: 1,
          y: 0,
          duration: ".5",
          ease: "back.out",
        }
      );
    }
    if (gameState === "won" || gameState === "lost") {
      //hide the place board UI
      if (tl.current) tl.current.pause();
      tl.current = gsap.timeline();
      tl.current.to([resultsCard.current], {
        autoAlpha: 0,
        y: 500,
        ease: "back.in",
      });
      tl.current.set(el.current, { autoAlpha: 0, zIndex: -1 });
    }
  }, [gameState]);

  return (
    <div ref={el} id="claim-ui" className="claim-ui">
      <div ref={resultsCard} className="claim-card">
        <img
          className="background"
          src={"../../assets/images/claim-ui-card.png"}
          alt=""
        />
        <img
          className="icon"
          src={"../../assets/images/claim-ui-icon.png"}
          alt=""
        />
        <h1 className="title" data-text="YOU REACHED THE FINISH LINE!">
          YOU REACHED THE FINISH LINE!
        </h1>

        <iframe
          // style={{ scale: 0.8 }}
          //src="../../prize-form.html"
          src={
            gameState === "claim" || gameState === "won" || gameState === "lost"
              ? "https://playtogetherwintogetherentry.com"
              : ""
          }
          marginWidth="0"
          marginHeight="0"
          hspace="0"
          vspace="0"
          frameBorder="0"
          scrolling="no"
        ></iframe>
      </div>
    </div>
  );
}

export default ClaimUI;
