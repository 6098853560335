import "./Terms.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../data/data";
import gsap from "gsap";
import useStore from "../../store";
// import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";

function Terms(props) {
  const appState = useStore((state) => state.appState);
  const setAppState = useStore((state) => state.setAppState);
  const setIsPlacingBoard = useStore((state) => state.setIsPlacingBoard);
  const setIsARActive = useStore((state) => state.setIsARActive);
  const setShowAgeGate = useStore((state) => state.setShowAgeGate);

  const el = useRef();
  const tl = useRef();
  const background = useRef();
  const card = useRef();

  useEffect(() => {
    // intro
    if (tl.current) tl.current.pause();
    if (appState === "terms") {
      //setShowAgeGate(true);
      tl.current = gsap.timeline({ delay: 0.5 });
      tl.current.set(el.current, { autoAlpha: 1, zIndex: 1100 });
      tl.current.fromTo(
        background.current,
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 0.5 }
      );
      tl.current.fromTo(
        [card.current],
        { autoAlpha: 1, y: 800 },
        { autoAlpha: 1, y: 0, duration: 0.7, ease: "back.out" }
      );
    } else {
      tl.current = gsap.timeline({});
      tl.current.to([card.current], {
        autoAlpha: 0,
        y: 800,
        duration: 0.5,
        ease: "back.in",
      });
      tl.current.to(background.current, { autoAlpha: 0, duration: 0.5 });

      tl.current.set(el.current, { autoAlpha: 0, zIndex: -1 });
    }
  }, [appState]);

  const closeClicked = () => {
    setAppState("landing");
  };

  return (
    <div ref={el} className={`terms`}>
      <img
        ref={background}
        className="background"
        src="../../assets/images/terms-background.jpg"
        alt=""
      />
      <div ref={card} className="card">
        <button onClick={closeClicked} className="close-button">
          <img src="../../assets/images/close.svg" alt="close" />
        </button>
        <h1 className="title">Terms & Conditions</h1>
        <div className="text-area">
          <p>
            Lorem ipsum dolor sit amet consectetur. Sapien ornare nunc tristique
            nisi scelerisque feugiat tincidunt amet. Sed in magna suspendisse
            netus orci morbi non. Malesuada justo urna eu ut mi. Sagittis
            aliquet dui praesent gravida ut faucibus aliquet elementum. Mi nunc
            augue sem purus. At neque nibh luctus eget.
          </p>
          <p>
            Faucibus commodo dis venenatis metus sed pellentesque accumsan amet.
            Tellus dui porta eget tempor lectus arcu eu arcu. At eget tortor
            malesuada arcu amet nisi lorem eu condimentum. Ultrices elementum
            lorem egestas ipsum ac. Placerat quis sed eu aliquam. Purus eget
            egestas id euismod ornare amet augue semper libero. Volutpat amet in
            sem purus. Aliquam libero amet diam tellus. Ultricies adipiscing
            ligula erat volutpat ultrices. Amet nisl ultrices morbi lorem netus
            ullamcorper tortor mauris.
          </p>
          <p>
            Egestas est porttitor ut morbi massa arcu. In tortor nullam nunc
            egestas purus ipsum pulvinar ornare. Placerat feugiat condimentum et
            nec iaculis. Cum aliquam hendrerit elementum nam sapien mattis.
            Velit venenatis eu pellentesque sed porttitor.
          </p>
          <p>
            Faucibus commodo dis venenatis metus sed pellentesque accumsan amet.
            Tellus dui porta eget tempor lectus arcu eu arcu. At eget tortor
            malesuada arcu amet nisi lorem eu condimentum. Ultrices elementum
            lorem egestas ipsum ac. Placerat quis sed eu aliquam. Purus eget
            egestas id euismod ornare amet augue semper libero. Volutpat amet in
            sem purus. Aliquam libero amet diam tellus. Ultricies adipiscing
            ligula erat volutpat ultrices. Amet nisl ultrices morbi lorem netus
            ullamcorper tortor mauris.
          </p>
          <p>
            Egestas est porttitor ut morbi massa arcu. In tortor nullam nunc
            egestas purus ipsum pulvinar ornare. Placerat feugiat condimentum et
            nec iaculis. Cum aliquam hendrerit elementum nam sapien mattis.
            Velit venenatis eu pellentesque sed porttitor.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Terms;
