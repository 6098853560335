import "./SnackTimeUI.scss";
import { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import { data } from "../../../data/data";
import useStore from "../../../store";
import gsap from "gsap";
// import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";

function SnackTimeUI(props) {
  const setPlaySound = useStore((state) => state.setPlaySound);
  const appState = useStore((state) => state.appState);
  //const setAppState = useStore((state) => state.setAppState);
  const gameState = useStore((state) => state.gameState);
  const setGameState = useStore((state) => state.setGameState);
  const snacksCollected = useStore((state) => state.snacksCollected);
  const setSnacksCollected = useStore((state) => state.setSnacksCollected);
  const el = useRef();
  const tl = useRef();

  const icon = useRef();
  const message = useRef();
  const scoreText = useRef();
  const scoreContainer = useRef();

  const [score, setScore] = useState(0);

  const [firstTime, setFirstTime] = useState(true);

  //TODO: Add lsitenr for appState to show this el when game

  // Add listnet fot when snacksCollected changes
  // in that add an aniamtion to increment the score, look at score increment pogba?
  // a little bounce on each increment??

  useEffect(() => {
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline({ delay: 0 });
    if (gameState === "waitingToRoll" && firstTime) {
      setFirstTime(false);
      //show title
      tl.current.set(el.current, { zIndex: 2100, autoAlpha: 1 });
      tl.current.set(message.current, {
        "transform-origin": "left",
        scaleX: 0,
      });
      tl.current.fromTo(
        [icon.current, scoreContainer.current],
        { y: 300, scale: 0.5, autoAlpha: 0 },
        {
          stagger: 0.2,
          autoAlpha: 1,
          y: 0,
          scale: 1,
          duration: 0.5,
          ease: "back.out",
        }
      );
      // tl.current.to(el.current, {
      //   y: 0,
      //   scale: 1,
      //   autoAlpha: 0,
      //   duration: 0.3,
      //   ease: "back.in",
      //   delay: 2,
      // });
      // tl.current.set(el.current, { autoAlpha: 0, zIndex: -1 });
      // tl.current.call(() => {
      //   setGameState("waitingToRoll");
      // });
    }
  }, [gameState]);

  useEffect(() => {
    // TODO: THIS is firing a lot, find out why.
    //console.log("snacksCollected", snacksCollected);
    if (!snacksCollected) return;
    let o = { s: score };
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline({ delay: 0 });
    tl.current.to(o, {
      s: snacksCollected,
      onUpdate: () => {
        setScore(o.s);
        console.log("setting score to ", o.s);
        setPlaySound("snack");
      },
      ease: "steps(" + (snacksCollected - score) + ")",
      duration: 0.7,
    });
    tl.current.to(
      message.current,
      { scaleX: 1, ease: "back.out", duration: 0.5 },
      0.5
    );
    tl.current.fromTo(
      scoreContainer.current,
      { scale: 1.0, y: 0 },
      {
        yoyo: true,
        repeat: 1,
        y: -10,
        scale: 2,
        ease: "back.out",
        duration: 0.7,
      }
    );
    tl.current.to(message.current, {
      scaleX: 0,
      ease: "back.in",
      duration: 0.5,
    });
    tl.current.call(() => {
      setScore(snacksCollected);
    });
  }, [snacksCollected]);

  return (
    <div ref={el} className="snack-time-ui">
      <img
        ref={icon}
        className="icon"
        src="../../assets/images/snack-counter-icon.png"
        alt=""
      />
      <div ref={scoreContainer} className="score-container">
        <img
          className="score-background"
          src="../../assets/images/snack-counter-score-background.png"
          alt=""
        />
        <h1 ref={scoreText} data-text={score || 0} className="score">
          {score || 0}
        </h1>
      </div>
      <img
        ref={message}
        className="message"
        src="../../assets/images/snacks-collected.png"
        alt=""
      />
    </div>
  );
}

export default SnackTimeUI;
