import "./PlaceBoxUI.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../../data/data";
import useStore from "../../../store";
import gsap from "gsap";
// import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";

function PlaceBoxUI(props) {
  const setPlaySound = useStore((state) => state.setPlaySound);
  const appState = useStore((state) => state.appState);
  //const setAppState = useStore((state) => state.setAppState);
  const setIsPlacingBoard = useStore((state) => state.setIsPlacingBoard);
  const isPlacingBoard = useStore((state) => state.isPlacingBoard);
  const setShowInstructionsButton = useStore(
    (state) => state.setShowInstructionsButton
  );
  const setGameState = useStore((state) => state.setGameState);
  const el = useRef();
  const placeBoxButton = useRef();
  const messageBoxRef = useRef();
  const tl = useRef();

  useEffect(() => {
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline({ delay: 0.5 });
    if (isPlacingBoard) {
      //show the place board UI
      tl.current.set(el.current, { autoAlpha: 1, zIndex: 1000 });
      tl.current.set([messageBoxRef.current, placeBoxButton.current], {
        autoAlpha: 0,
        y: 100,
      });
      tl.current.to([messageBoxRef.current, placeBoxButton.current], {
        autoAlpha: 1,
        y: 0,
        stagger: 0.2,
        duration: 0.5,
        ease: "back.out",
      });
    } else {
      //hide the place board UI
      tl.current.to([messageBoxRef.current, placeBoxButton.current], {
        autoAlpha: 0,
        y: 100,
        stagger: 0.2,
        duration: 0.5,
        ease: "back.in",
      });
      tl.current.set(el.current, { autoAlpha: 0, zIndex: -1 });
      tl.current.call(() => {
        if (appState === "game") setShowInstructionsButton(true);
      });
    }
  }, [isPlacingBoard]);

  const placeBox = () => {
    setPlaySound("click");
    setIsPlacingBoard(false);
    setGameState("openBox");
    setTimeout(() => {
      setGameState("showBoard");
    }, 1500);
    setTimeout(() => {
      //setGameState("starting");
    }, 3500);
  };

  return (
    <div ref={el} className="place-box-ui">
      <div ref={messageBoxRef} className="message-box">
        <img
          className="place-box-icon"
          src="../../assets/images/place-box-icon.png"
          alt="start"
        />
        <p className="text">
          Drag and move to place the Frito-Lay Variety Pack. Pinch to change the
          size. Pinch and move to rotate.
        </p>
      </div>
      <button
        ref={placeBoxButton}
        className="place-box-button"
        onClick={placeBox}
        id="place-board-button"
      >
        <img src="../../assets/images/place-box-button.png" alt="start" />
      </button>
    </div>
  );
}

export default PlaceBoxUI;
