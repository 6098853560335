import "./GameUI.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../data/data";
import gsap from "gsap";
import useStore from "../../store";
import PlaceBoxUI from "./PlaceBoxUI/PlaceBoxUI";
import StartUI from "./StartUI/StartUI";
import InstructionButton from "./InstructionButton/InstructionButton";
import InstructionsUI from "./InstructionsUI/InstructionsUI";
import TapToRollUI from "./TapToRollUI/TapToRollUI";
import RollUI from "./RollUI/RollUI";
import { use } from "i18next";
import ZoneUI from "./ZoneUI/ZoneUI";
import SnackTimeUI from "./SnackTimeUI/SnackTimeUI";
import FinalUI from "./FinalUI/FinalUI";
// import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";

function GameUI(props) {
  const appState = useStore((state) => state.appState);
  const gameState = useStore((state) => state.gameState);
  const setGameState = useStore((state) => state.setGameState);
  const setSnacksCollected = useStore((state) => state.setSnacksCollected);
  const snacksCollected = useStore((state) => state.snacksCollected);
  const currentPosition = useStore((state) => state.currentPosition);
  const setCurrentPosition = useStore((state) => state.setCurrentPosition);
  const rollNumber = useStore((state) => state.rollNumber);
  const setRollNumber = useStore((state) => state.setRollNumber);
  const currentZone = useStore((state) => state.currentZone);
  const setCurrentZone = useStore((state) => state.setCurrentZone);
  const activeAnimation = useStore((state) => state.activeAnimation);
  const setActiveAnimation = useStore((state) => state.setActiveAnimation);
  const setShowInstructionsButton = useStore(
    (state) => state.setShowInstructionsButton
  );

  const el = useRef();
  const tl = useRef();
  const tl2 = useRef();

  const animations = [];
  animations[1] = "spinner"; //spinner spins
  animations[2] = "spinner";
  animations[3] = "spinner";
  animations[4] = "spinner";
  animations[5] = "spinner";
  animations[6] = "umbrellas"; // umbrellas open
  animations[7] = "umbrellas";
  animations[8] = "tiles"; // tiles tip over like dominoes
  animations[9] = "tiles";
  animations[10] = "tiles";
  animations[11] = "chester"; // chester appears from ground
  animations[12] = "chester";
  animations[13] = "chester"; // pieces slide into each other
  animations[14] = "sorry";
  animations[15] = "sorry";
  animations[16] = "crane"; // connect4 crane drops in piece
  animations[17] = "crane";
  animations[18] = "crane";
  animations[19] = "crane";
  animations[20] = "crane";
  animations[21] = "money"; // money particle effect
  animations[22] = "money";
  animations[23] = "plane"; // monppoly man in plane
  animations[24] = "plane";
  animations[25] = "plane";
  animations[26] = "end"; // bags explore , particles

  const snacks = [];
  snacks[1] = 1;
  snacks[2] = 3;
  snacks[3] = 1;
  snacks[4] = 3;
  snacks[5] = 1;
  snacks[6] = 1;
  snacks[7] = 3;
  snacks[8] = 1;
  snacks[9] = 3;
  snacks[10] = 1;
  snacks[11] = 3;
  snacks[12] = 1;
  snacks[13] = 3;
  snacks[14] = 1;
  snacks[15] = 1;
  snacks[16] = 1;
  snacks[17] = 3;
  snacks[18] = 1;
  snacks[19] = 3;
  snacks[20] = 1;
  snacks[21] = 1;
  snacks[22] = 3;
  snacks[23] = 1;
  snacks[24] = 3;
  snacks[25] = 1;
  snacks[26] = 0;

  useEffect(() => {
    console.log("game state cahnge to ", gameState);
    // game logic, maangmenet

    if (gameState === "movingPlayer") {
      // NOTE: This is how long we have to move the car from current to ne spot in AR
      if (tl2.current) tl2.current.pause();
      tl2.current = gsap.timeline();
      const o = { pos: currentPosition || 0 };
      let _o;
      let firstTime = false;
      if (!currentPosition) firstTime = true;
      tl2.current.to(o, {
        delay: firstTime ? 4 : 1,
        pos: currentPosition + rollNumber,
        duration: rollNumber * 0.8,
        ease: "steps(" + parseInt(rollNumber) + ")",
        onUpdate: (e) => {
          // determine if triggered animation
          if (o.pos !== _o) {
            console.log("moving to space", o.pos);
            _o = o.pos;
            if (o.pos < 27) {
              setActiveAnimation(animations[o.pos]);

              if (o.pos > 0 && o.pos <= 5) setCurrentZone("life");
              if (o.pos > 5 && o.pos <= 10) setCurrentZone("scrabble");
              if (o.pos > 10 && o.pos <= 15) setCurrentZone("sorry");
              if (o.pos > 15 && o.pos <= 20) setCurrentZone("connect4");
              if (o.pos > 20 && o.pos <= 25) setCurrentZone("monopoly");
              if (o.pos > 25) setCurrentZone("end");
            }
          }
        },
        onComplete: () => {
          console.log("complete moving", o.postion);
          setGameState("updatePosition");
          setSnacksCollected(snacksCollected + (snacks[o.pos] || 0));
        },
      });
    }

    if (gameState === "updatePosition") {
      const t =
        currentPosition + rollNumber > 25 ? 26 : currentPosition + rollNumber;
      console.log("target postion will be " + t);
      // determine zone.

      if (tl.current) tl.current.pause();
      tl.current = gsap.timeline();
      if (t < 26) {
        tl.current.call(
          () => {
            setGameState("waitingToRoll");
          },
          null,
          4
        );
      }

      // incremnt the player position
      setCurrentPosition(currentPosition + rollNumber);
      // reset the roll number
      setRollNumber(0);
    }

    if (gameState === "end") {
      setShowInstructionsButton(false);
    }
  }, [gameState]);

  //tmp
  useEffect(() => {
    console.log("Entering zone: " + currentZone);
    if (currentZone === "end") {
      setShowInstructionsButton(false);
    }
  }, [currentZone]);

  return (
    <div ref={el} className={`game-ui`}>
      <PlaceBoxUI />
      <StartUI />
      <InstructionButton />
      <InstructionsUI />
      <TapToRollUI />
      <RollUI />
      <ZoneUI />
      <FinalUI />
      <SnackTimeUI />
    </div>
  );
}

export default GameUI;
