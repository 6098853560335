import "./SoundButton.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../../data/data";
import useStore from "../../../store";
import gsap from "gsap";
// import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";

function SoundButton(props) {
  const soundOn = useStore((state) => state.soundOn);
  const setSoundOn = useStore((state) => state.setSoundOn);

  const el = useRef();
  const tl = useRef();

  const soundClicked = () => {
    //setShowInstructionsButton(false);
    console.log("sound button clicked");
    localStorage.setItem("soundOn", !soundOn);
    setSoundOn(!soundOn);
  };

  useEffect(() => {
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline({ delay: 2 });
    tl.current.set(el.current, { zIndex: 3200 });
    // show
    tl.current.fromTo(
      [el.current],
      { y: 100, autoAlpha: 0 },
      {
        autoAlpha: 1,
        y: 0,
        duration: 0.5,
        ease: "back.out",
      }
    );
  }, []);

  return (
    <button
      onClick={soundClicked}
      ref={el}
      className="sound-button"
      id="sound-mute-button"
    >
      <img
        className="sound-on"
        style={{ visibility: soundOn ? "visible" : "hidden" }}
        src="../../assets/images/sound-icon-on.png"
        alt="sound on"
      />
      <img
        className="sound-off"
        style={{ visibility: !soundOn ? "visible" : "hidden" }}
        src="../../assets/images/sound-icon-off.png"
        alt="sound off"
      />
    </button>
  );
}

export default SoundButton;
