import * as THREE from "three";
import { TextureLoader } from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { Gesture } from "@use-gesture/vanilla";
import useStore from "../../store";
import gsap from "gsap";
import { render } from "@testing-library/react";

// special code to handle iFrame child calling here
function onMessage(event) {
  console.log("event data", event.data);
  if (!event.data || event.data.type) return;
  if (event.data === "won") {
    useStore.setState({ gameState: "won" });
  }
  if (event.data === "lost") {
    useStore.setState({ gameState: "lost" });
  }
}
if (window.addEventListener) {
  window.addEventListener("message", onMessage, false);
} else if (window.attachEvent) {
  window.attachEvent("onmessage", onMessage, false);
}
// end message listenr from iFrame child

window.THREE = THREE;
const frustum = new THREE.Frustum();
const projScreenMatrix = new THREE.Matrix4();

const manager = new THREE.LoadingManager();
const dracoLoader = new DRACOLoader();

let startText;
let moneyState = "hidden";
const fadeAnim = { scale: 0, opacity: 1 };
let moneyMesh;
const moneyCount = 700;
const size = { x: 3.5, y: 4, z: 3.5 };
const moneyParticles = [];
let moneyFallSpeed = 0.1;
let moneyDummy = new THREE.Object3D();
let moneyMesh2;
const moneyParticles2 = [];
let moneyDummy2 = new THREE.Object3D();
let money50Texture;

let particleMesh1;
const pCount = 15000;
const particlesArray = [];
let particleFallSpeed = 0.125;
let particleDummy = new THREE.Object3D();
const fadeAnimp1 = { scale: 0, opacity: 1 };
const fadeAnimp2 = { scale: 0, opacity: 1 };
const fadeAnimp3 = { scale: 0, opacity: 1 };
let pState = "hidden";

let arrow;

let firstTime = true;

const pColors = [
  new THREE.Color(0xf9e03c),
  new THREE.Color(0xff95c3),
  new THREE.Color(0xff6a6a),
  new THREE.Color(0x0094d5),
  new THREE.Color(0x93d419),
  new THREE.Color(0xffffff),
];

const chesterImages = [];

chesterImages[0] = {
  file: "../../assets/models/Cheetah_Dorito_Wind_Final_Updated_01.jpg",
  texture: null,
};
chesterImages[1] = {
  file: "../../assets/models/Cheetah_Dorito_Wind_Final_Updated_02.jpg",
  texture: null,
};
chesterImages[2] = {
  file: "../../assets/models/Cheetah_Dorito_Wind_Final_Updated_03.jpg",
  texture: null,
};
chesterImages[3] = {
  file: "../../assets/models/Cheetah_Dorito_Wind_Final_Updated_04.jpg",
  texture: null,
};

const snackBags = []; //Crisp_Bags_Path

// set up assts to load here
window.models = [
  {
    name: "box",
    file: "../../assets/models/BoxOpeningV08.glb",
    model: null,
  },
  {
    name: "board",
    file: "../../assets/models/Prod_249_Layout.glb",
    model: null,
  },
  {
    name: "car",
    file: "../../assets/models/Car_anim_008.glb",
    model: null,
    worldPos: new THREE.Vector3(),
    progress: 0,
  },
  {
    name: "spinner",
    file: "../../assets/models/Spinner_and_Dorito_anim_004.glb",
    model: null,
  },
  {
    name: "umbrellas",
    file: "../../assets/models/Picnic_Tables_anim_006.glb",
    model: null,
  },
  {
    name: "tiles",
    file: "../../assets/models/Domino_anim_001.glb",
    model: null,
  },
  {
    name: "chester",
    file: "../../assets/models/Cheetah_anim_004.glb",
    model: null,
  },
  {
    name: "sorry",
    file: "../../assets/models/Sorry_Piece_anim_006.glb",
    model: null,
  },
  {
    name: "crane",
    file: "../../assets/models/Crane_anim_005.glb",
    model: null,
  },
  {
    name: "plane",
    file: "../../assets/models/Plane_anim_004.glb",
    model: null,
  },
  {
    name: "train",
    file: "../../assets/models/Train_anim_003.glb",
    model: null,
  },
  {
    name: "particles",
    file: "../../assets/models/Chips_Particles.glb",
    model: null,
  },
  {
    name: "money",
    file: "../../assets/models/Monopoly_Bill_500.glb",
    model: null,
  },
  {
    name: "chips",
    file: "../../assets/models/Crisps_anim_004.glb",
    model: null,
  },
];
// for easy access:
const box = window.models.find((m) => m.name === "box");
const board = window.models.find((m) => m.name === "board");
const car = window.models.find((m) => m.name === "car");
const spinner = window.models.find((m) => m.name === "spinner");
const umbrellas = window.models.find((m) => m.name === "umbrellas");
const tiles = window.models.find((m) => m.name === "tiles");
const chester = window.models.find((m) => m.name === "chester");
const sorry = window.models.find((m) => m.name === "sorry");
const crane = window.models.find((m) => m.name === "crane");
const plane = window.models.find((m) => m.name === "plane");
const train = window.models.find((m) => m.name === "train");
const particles = window.models.find((m) => m.name === "particles");
const money = window.models.find((m) => m.name === "money");
const chips = window.models.find((m) => m.name === "chips");

// use group to load everything relative to the base board
window.boardGroup = new THREE.Object3D();
window.boardGroup.visible = false;
window.camera = new THREE.Object3D();

let appState = null;
let gameState = null;
let rollNumber = null;
let currentPosition = 0;
let locked = false;
let isPlacingBoard = null;
let activeAnimation = null;

// map of precent progress and spaces on board.
// this may change if we haev to use a 3D path instead of animation
let pathKeyFrames = [];
pathKeyFrames[0] = 0;

pathKeyFrames[1] = 0.06;
pathKeyFrames[2] = 0.1;
pathKeyFrames[3] = 0.14;
pathKeyFrames[4] = 0.195;
pathKeyFrames[5] = 0.232;

pathKeyFrames[6] = 0.27;
pathKeyFrames[7] = 0.32;
pathKeyFrames[8] = 0.35;
pathKeyFrames[9] = 0.395;
pathKeyFrames[10] = 0.432;

pathKeyFrames[11] = 0.465;
pathKeyFrames[12] = 0.507;
pathKeyFrames[13] = 0.542;
pathKeyFrames[14] = 0.58;
pathKeyFrames[15] = 0.61;

pathKeyFrames[16] = 0.64;
pathKeyFrames[17] = 0.67;
pathKeyFrames[18] = 0.71;
pathKeyFrames[19] = 0.745;
pathKeyFrames[20] = 0.785;

pathKeyFrames[21] = 0.82;
pathKeyFrames[22] = 0.855;
pathKeyFrames[23] = 0.9;
pathKeyFrames[24] = 0.93;
pathKeyFrames[25] = 0.96;

// React to changes in Global Store States
const placingBoardSub = useStore.subscribe((state) => {
  if (isPlacingBoard === state.isPlacingBoard) return;
  if (!window.XR8) return;
  isPlacingBoard = state.isPlacingBoard;
  if (isPlacingBoard) {
    // do things if needed on change of this state
    console.log("isPlacingBoard", isPlacingBoard);
  }
});

const unsubAppState = useStore.subscribe((state) => {
  //console.log(state.appState);
  if (appState === state.appState) return;
  appState = state.appState;

  if (appState === "game") {
    console.log("AR pipeline got appState", appState);
    // example code of doing soemthing to react
    // window.characters[window.activeTurtle].animations["Intro"].paused = false;
    // window.characters[window.activeTurtle].animations["Intro"].play();
    // window.characters[window.activeTurtle].model.scene.visible = true;
  }
});

const unsubGameState = useStore.subscribe((state) => {
  if (gameState === state.gameState) return;
  gameState = state.gameState;

  if (gameState === "openBox") {
    box.animations["All Animations"].clampWhenFinished = true;
    box.animations["All Animations"].setLoop(THREE.LoopOnce);
    box.animations["All Animations"].reset().play();
  }

  if (gameState === "showBoard") {
    const tl = gsap.timeline({});
    tl.call(() => {
      box.model.scene.visible = false;
      board.model.scene.visible = true;
      board.model.scene.position.y = -0.05;
      board.model.scene.scale.y = 0.1;
    });
    tl.to(
      board.model.scene.scale,
      {
        y: 1.0,
        ease: "elastic.out",
        duration: 2,
      },
      0.1
    );
    tl.to(
      board.model.scene.position,
      {
        y: 0,
        duration: 0.5,
      },
      0.1
    );

    window.models.forEach((m, i) => {
      if (
        m.name === "spinner" ||
        m.name === "tiles" ||
        m.name === "crane" ||
        m.name === "sorry" ||
        m.name === "train" ||
        m.name === "umbrellas"
      ) {
        m.model.scene.scale.y = 0.00000001;
        m.model.scene.visible = true;
        tl.to(
          m.model.scene.scale,
          {
            y: 1.0,
            ease: "elastic.out",
            duration: 1.5,
          },
          1.5 + i * 0.1
        );
      }
    });

    startText.position.y = 1;
    startText.visible = false;
    tl.fromTo(
      startText.position,
      { y: 1 },
      {
        y: 0.1,
        ease: "back.out",
        duraiton: 1.5,
        onStart: () => {
          startText.visible = true;
        },
      },
      2.5
    );

    //boucne in car
    car.model.scene.position.y = 1;
    car.model.scene.visible = false;
    car.model.scene.scale.y = 1.0;
    gsap.fromTo(
      car.model.scene.position,
      { y: 0.5 },
      {
        y: 0,
        ease: "bounce.out",
        duraiton: 4,
        delay: 2,
        onStart: () => {
          car.model.scene.visible = true;
        },
        onComplete: () => {
          useStore.setState({ gameState: "starting" });
        },
      }
    );
  }

  if (gameState === "starting") {
    train.animations["All Animations"].reset().play();
    useStore.setState({ playSound: "train" });
  }

  if (gameState === "movingPlayer") {
    console.log("Moving the Player in AR. Numer of spaces: " + rollNumber);

    let tl = gsap.timeline({ delay: firstTime ? 4 : 1 });

    if (firstTime) {
      gsap.to(startText.scale, {
        x: 0,
        y: 0,
        z: 0,

        ease: "back.in",
        duraiton: 0.5,
        onComplete: () => {
          startText.visible = false;
        },
      });

      car.animations["Car_HopIn"].setLoop(THREE.LoopOnce);
      car.animations["Car_HopIn"].reset().play();
      firstTime = false;
      arrow.visible = true;
      gsap.to(arrow.position, {
        y: 0.02,
        delay: 2,
        repeat: -1,
        yoyo: true,
        duration: 1.0,
        repeatDelay: 0.2,
        ease: "sine.inOut",
      });
    }

    tl.to(car, {
      progress:
        currentPosition + rollNumber > 25
          ? 1
          : pathKeyFrames[currentPosition + rollNumber],
      duration: rollNumber * 0.8,
      ease: "sine.inOut",
      onUpdate: () => {
        //console.log(window.car.progress);
      },
      onComplete: () => {
        // TODO: figure out which bag if any to anaimte.
        let _i = null;
        if (currentPosition + rollNumber === 2) _i = 0;
        if (currentPosition + rollNumber === 4) _i = 1;
        if (currentPosition + rollNumber === 7) _i = 2;
        if (currentPosition + rollNumber === 9) _i = 3;
        if (currentPosition + rollNumber === 11) _i = 4;
        if (currentPosition + rollNumber === 13) _i = 5;
        if (currentPosition + rollNumber === 17) _i = 6;
        if (currentPosition + rollNumber === 19) _i = 7;
        if (currentPosition + rollNumber === 22) _i = 8;
        if (currentPosition + rollNumber === 24) _i = 9;

        // anaimte snck if intersected
        if (_i !== null) {
          gsap.to(snackBags[_i].position, {
            y: 0.05,
            ease: "back.out",
            duration: 1,
          });
          gsap.to(snackBags[_i].scale, {
            x: 1.5,
            y: 1.5,
            z: 1.5,
            ease: "sine.in",
            duration: 1.5,
            delay: 0.0,
          });
          gsap.to(snackBags[_i].scale, {
            x: 0,
            y: 0,
            z: 0,
            ease: "sine.in",
            duration: 0.3,
            delay: 1.5,
          });
          gsap.to(snackBags[_i].position, {
            y: 0.0,
            ease: "back.in",
            duration: 0.3,
            delay: 1.5,
          });
        }
      },
    });

    //window.car.animations["Car_Path"].play();
  }
});

const unsubActiveAnimation = useStore.subscribe((state) => {
  if (activeAnimation === state.activeAnimation) return;
  activeAnimation = state.activeAnimation;

  console.log("Trigger New Aniamtion in AR: " + activeAnimation);

  // spinner anaimtion - 1
  if (activeAnimation === "spinner") {
    spinner.animations["Spinner"].setLoop(THREE.LoopOnce);
    spinner.animations["Spinner"].reset().play();
    useStore.setState({ playSound: "spinner" });

    // if i need it there is also Dorito_Loop
  }

  // pegs anaimtion  - 2
  if (activeAnimation === "pegs") {
    //car.animations["All Animations"].play();
    spinner.animations["Spinner"].setLoop(THREE.LoopOnce);
    spinner.animations["Spinner"].reset().play();
    useStore.setState({ playSound: "spinner" });
  }

  // umbrellas anaimtion - 3
  if (activeAnimation === "umbrellas") {
    umbrellas.animations["All Animations"].setLoop(THREE.LoopOnce);
    umbrellas.animations["All Animations"].reset().play();
  }

  // tiles anaimtion - 4
  if (activeAnimation === "tiles") {
    tiles.animations["All Animations"].clampWhenFinished = true;
    tiles.animations["All Animations"].setLoop(THREE.LoopOnce);
    tiles.animations["All Animations"].reset().play();
    useStore.setState({ playSound: "tiles" });
  }

  // chester anaimtion - 5
  if (activeAnimation === "chester") {
    chester.model.scene.visible = true;
    chester.model.scene.scale.y = 1.0;
    chester.animations["Cheetah_emerging"].setLoop(THREE.LoopOnce);
    chester.animations["Cheetah_emerging"].reset().play();
    chester.animations["Cheetah_nod"].reset().play();

    // TODO: Add image seqeunce / texture swap in timeput loop for neeon sign

    const tl3 = gsap.timeline({ repeat: -1, repeatDelay: 0.8 });
    tl3.call(
      () => {
        chester.material.map = chesterImages[1].texture;
        chester.material.needsUpdate = true;
      },
      null,
      0
    );
    tl3.call(
      () => {
        chester.material.map = chesterImages[2].texture;
        chester.material.needsUpdate = true;
      },
      null,
      0.4
    );
    tl3.call(
      () => {
        chester.material.map = chesterImages[3].texture;
        chester.material.needsUpdate = true;
      },
      null,
      0.8
    );
    tl3.call(
      () => {
        chester.material.map = chesterImages[0].texture;
        chester.material.needsUpdate = true;
      },
      null,
      1.2
    );
    //Cheetah_Dorito_Wind1
  }

  // sorry anaimtion - 6
  if (activeAnimation === "sorry") {
    sorry.animations["All Animations"].clampWhenFinished = true;
    sorry.animations["All Animations"].setLoop(THREE.LoopOnce);
    sorry.animations["All Animations"].reset().play();
    useStore.setState({ playSound: "sorry" });
  }

  // crane anaimtion - 7
  if (activeAnimation === "crane") {
    crane.animations["Crane_anim"].setLoop(THREE.LoopOnce);
    crane.animations["Crane_anim"].reset().play();
    setTimeout(() => {
      useStore.setState({ playSound: "crane" });
    }, 4000);
  }

  // drop anaimtion - 8
  // if (activeAnimation === "drop") {
  //   crane.animations["Token_spill02"].setLoop(THREE.LoopOnce);
  //   crane.animations["Token_spill02"].reset().play();
  //   // crane.animations["Flag_Idle_anim03"].play();
  // }

  // money anaimtion - 9
  if (activeAnimation === "money") {
    // show money
    moneyState = "start";
  }

  // plane anaimtion - 10
  if (activeAnimation === "plane") {
    plane.model.scene.scale.y = 1.0;
    plane.model.scene.visible = true;
    plane.animations["All Animations"].reset().play();
  }

  if (activeAnimation === "end") {
    // hide money
    moneyState = "stop";
    pState = "start";
    chips.animations["All Animations"].clampWhenFinished = true;
    chips.animations["All Animations"].setLoop(THREE.LoopOnce);
    chips.animations["All Animations"].reset().play();
    chips.model.scene.visible = true;
  }
});

const unsubRollNumber = useStore.subscribe((state) => {
  if (rollNumber === state.rollNumber) return;
  rollNumber = state.rollNumber;
});
const unsubCurrentPosition = useStore.subscribe((state) => {
  if (currentPosition === state.currentPosition) return;
  currentPosition = state.currentPosition;
});

// create loader.
setTimeout(() => {
  // load board
  manager.onProgress = (url, itemsLoaded, itemsTotal) => {
    console.log((itemsLoaded / itemsTotal) * 100 + "% loaded");
    useStore.setState({ loadingProgress: itemsLoaded / itemsTotal });
  };
  manager.onLoad = (e) => {
    console.log("all models loaded");
    useStore.setState({ showLoader: false });

    // set up placement box
    box.model.scene.visible = true;
    box.model.scene.traverse((m) => {
      if (m.isMesh && m.name === "M_Gabarit_board") {
        console.log("here box mesh", m.material);
        m.material.opacity = 0.7;
        m.material.transparent = true;
      }
    });

    // set up initial settings
    car.animations["Car_Path"].play();
    car.animations["Car_Path"].time = 0;
    car.animations["Car_Path"].paused = true;

    car.animations["Car_HopIn"].play();
    car.animations["Car_HopIn"].time = 0;
    car.animations["Car_HopIn"].paused = true;

    car.model.scene.traverse((m) => {
      if (m.isMesh && m.name === "Triangle_MDL") {
        arrow = m;
      }
    });
    console.log(arrow);
    arrow.visible = false;

    board.model.scene.visible = true;
    board.model.scene.scale.y = 0.00000001;
    board.model.scene.position.y = -100000;

    // get ref to each snack bag
    board.model.scene.traverse((m) => {
      if (m.isMesh && m.name.includes("Crisp_Bags_Path")) {
        snackBags[parseInt(m.name.substr(m.name.length - 2)) || 0] = m;
      }
    });

    // spinner fix
    spinner.animations["Dorito_Loop"].play();
    spinner.animations["Dorito_Loop"].time = 0;
    spinner.animations["Dorito_Loop"].paused = true;
    spinner.animations["Dorito_Loop"].fadeOut(0);
    spinner.animations["Spinner"].play();
    spinner.animations["Spinner"].time = 0;
    spinner.animations["Spinner"].paused = true;
    spinner.animations["Spinner"]
      .getMixer()
      .addEventListener("finished", function (e) {
        spinner.animations["Dorito_Loop"].reset();
        // spinner.animations["Dorito_Loop"].setDuration(2);
        spinner.animations["Dorito_Loop"].fadeIn(1);
        spinner.animations["Dorito_Loop"].paused = false;
        spinner.animations["Dorito_Loop"].play();
      });

    // create money mesh
    const money1 = money.model.scene.getObjectByName("Bill_500");
    const money1Geo = money1.geometry.clone();
    const defaultTransform = new THREE.Matrix4();
    money1Geo.applyMatrix4(defaultTransform);
    const money1Mat = money1.material;
    moneyMesh = new THREE.InstancedMesh(money1Geo, money1Mat, moneyCount);
    moneyMesh.visible = false;
    moneyMesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage);
    window.boardGroup.add(moneyMesh);
    // money mesh 2
    const money2Geo = money1.geometry.clone();
    const defaultTransform2 = new THREE.Matrix4();
    money2Geo.applyMatrix4(defaultTransform2);
    console.log(money50Texture);
    const money2Mat = new THREE.MeshStandardMaterial({
      map: money50Texture,
      side: THREE.DoubleSide,
      roughness: 0.8,
      metalness: 0.1,
      color: 0xeeeeff,
    }); //update this to another texture!!!
    moneyMesh2 = new THREE.InstancedMesh(money2Geo, money2Mat, moneyCount);
    moneyMesh2.visible = false;
    moneyMesh2.instanceMatrix.setUsage(THREE.DynamicDrawUsage);
    window.boardGroup.add(moneyMesh2);

    // create particle mesh
    //console.log(particles.model.scene);
    const particle1 = particles.model.scene.getObjectByName("Dorito_Part007");
    const particleGeo1 = particle1.geometry.clone();
    particleGeo1.applyMatrix4(new THREE.Matrix4());
    const pmat1 = new THREE.MeshStandardMaterial({
      side: THREE.DoubleSide,
      roughness: 0.8,
      metalness: 0.1,
      color: 0xffffff,
    });
    particleMesh1 = new THREE.InstancedMesh(particleGeo1, pmat1, pCount);
    particleMesh1.visible = false;
    particleMesh1.instanceMatrix.setUsage(THREE.DynamicDrawUsage);
    particleMesh1.position.set(0.3, 0.05, -0.4);
    window.boardGroup.add(particleMesh1);

    for (let i = 0; i <= moneyCount; i++) {
      const p = {};
      p.position = {
        x: size.x * Math.random() - size.x / 2,
        y: size.y * Math.random(),
        z: size.z * Math.random() - size.z / 2,
      };

      let _r = Math.random();
      p.scale = {
        x: 0.5 + _r * 0.2,
        y: 0.5 + _r * 0.2,
        z: 0.5 + _r * 0.2,
      };

      p.rotation = {
        x: (Math.PI / 180) * 360 * Math.random(),
        y: (Math.PI / 180) * 360 * Math.random(),
        z: (Math.PI / 180) * 360 * Math.random(),
      };

      //p.startTime = Math.random() * 3;

      moneyParticles.push(p);

      // set 2
      const p2 = {};
      p2.position = {
        x: size.x * Math.random() - size.x / 2,
        y: size.y * Math.random(),
        z: size.z * Math.random() - size.z / 2,
      };

      let _r2 = Math.random();
      p2.scale = {
        x: 0.5 + _r2 * 0.2,
        y: 0.5 + _r2 * 0.2,
        z: 0.5 + _r2 * 0.2,
      };

      p2.rotation = {
        x: (Math.PI / 180) * 360 * Math.random(),
        y: (Math.PI / 180) * 360 * Math.random(),
        z: (Math.PI / 180) * 360 * Math.random(),
      };

      //p.startTime = Math.random() * 3;

      moneyParticles2.push(p2);
    }

    for (let i = 0; i <= pCount; i++) {
      const p = {};
      p.random = Math.random();

      const rho = Math.random() * size.x;
      const theta = Math.random() * 2 * Math.PI;
      const phi = Math.random() * Math.PI;

      const x = rho * Math.sin(phi) * Math.cos(theta);
      const y = rho * Math.sin(phi) * Math.sin(theta);
      const z = rho * Math.cos(phi);

      p.position = {
        x: x,
        y: y,
        z: z,
      };

      let _r = Math.random();

      p.scale = {
        x: 0.5 + _r * 0.2,
        y: 0.5 + _r * 0.2,
        z: 0.5 + _r * 0.2,
      };

      p.rotation = {
        x: (Math.PI / 180) * 360 * Math.random(),
        y: (Math.PI / 180) * 360 * Math.random(),
        z: (Math.PI / 180) * 360 * Math.random(),
      };

      //p.startTime = Math.random() * 3;
      p.color = pColors[Math.floor(Math.random() * 6)];
      particlesArray.push(p);
    }
    // set random colors on Particles
    for (let i = 0; i < pCount; i++) {
      const p = particlesArray[i];
      particleMesh1.setColorAt(i, p.color);
      // //moneyDummy.scale.set(p.scale.x, p.scale.y, p.scale.z);
      // particleDummy.updateMatrix();
      // particleMesh1.setMatrixAt(i, particleDummy.matrix);
    }
    particleMesh1.instanceMatrix.needsUpdate = true;
    particleMesh1.computeBoundingSphere();
    particleMesh1.instanceColor.needsUpdate = true;

    chester.model.scene.traverse((node) => {
      if (node.isMesh && node.material) {
        if (node.material.name === "Cheetah_Dorito_Wind1") {
          chester.material = node.material;
        }
      }
    });
  };

  window.models.forEach((m, i) => {
    const loader = new GLTFLoader(manager);
    dracoLoader.setDecoderPath("/examples/jsm/libs/draco/");
    loader.setDRACOLoader(dracoLoader);
    loader.load(m.file, (gltf) => {
      // update mesh and material props, can add flags to array above to customize
      gltf.scene.traverse((node) => {
        if (node.isMesh) {
          node.castShadow = true;
          node.receiveShadow = true;
          //if (m.name === "train") {

          // MIGHT NEED TO TUN THIS ON FOR CAR AND OTHER elemtns like train
          node.frustumCulled = false;
          //node.renderOrder = 100;
          // if (m.name === "box") {
          //   node.renderOrder = 0;
          // }
          //}
        }
      });
      m.model = gltf;
      m.mixer = new THREE.AnimationMixer(gltf.scene);
      m.animations = {};
      m.model.animations.forEach((clip) => {
        m.animations[clip.name] = m.mixer.clipAction(clip);
        if (m.name !== "car") {
          m.animations[clip.name].play();
          m.animations[clip.name].time = 0;
          m.animations[clip.name].paused = true;
        }
      });

      m.model.scene.visible = false;
      if (m.name === "box") {
        // rotate the box to offset it to match how it should be
        box.model.scene.rotation.y = (Math.PI / 180) * 90;
      }
      window.boardGroup.add(gltf.scene);
    });
  });

  // load some texture assets for money and posibly the chester

  const loader2 = new THREE.TextureLoader(manager);
  loader2.load(
    "../../assets/models/MONEY_50.jpg",
    function (texture) {
      money50Texture = texture;
      console.log(money50Texture);
    },
    undefined,

    // onError callback
    function (err) {
      console.error(err);
    }
  );

  chesterImages.forEach((c, i) => {
    loader2.load(
      c.file,
      function (texture) {
        texture.flipY = false;
        texture.colorSpace = THREE.SRGBColorSpace;
        chesterImages[i].texture = texture;
      },
      undefined,

      // onError callback
      function (err) {
        console.error(err);
      }
    );
  });

  //start texure
  loader2.load(
    "../../assets/images/start-text.png",
    function (texture) {
      //texture.flipY = false;
      texture.colorSpace = THREE.SRGBColorSpace;
      startText = new THREE.Mesh(
        new THREE.PlaneGeometry(1.62 * 0.08, 0.573 * 0.08),
        new THREE.MeshStandardMaterial({ map: texture, transparent: true })
      );
      //startText.scale.set(1.62 * 0.08, 0.573 * 0.08, 1);
      startText.position.set(-0.35, 0.09, 0.35);
      startText.visible = false;
      window.boardGroup.add(startText);
    },
    undefined,

    // onError callback
    function (err) {
      console.error(err);
    }
  );
}, 2500);

// Define an 8th Wall XR Camera Pipeline Module
const gameThreejsPipelineModule = () => {
  // Main function to populate 3D assets into scene
  const initXrScene = ({ scene, camera, renderer }) => {
    window.camera = camera; // ref for other functions later
    window.scene = scene; // ref for other functions later
    window.mixer = null;
    window.clock = new THREE.Clock();
    //renderer.setClearColor(0x000000, 0);
    const handleCameraStatusChange = (event) => {
      console.log("status change", event.detail.status);

      switch (event.detail.status) {
        case "failed":
          useStore.setState({ showPermissionError: true });

          console.log("Failed", event.detail.status);
          event.target.emit("realityerror");

          break;
      }
    };
    scene.addEventListener("camerastatuschange", handleCameraStatusChange);

    // THREE.ColorManagement.enabled = true;
    // renderer.outputColorSpace = THREE.SRGBColorSpace;

    window.cameraTracker = new THREE.Group();
    window.boardGroup.position.z = -2;
    window.boardGroup.position.x = 0;
    window.boardGroup.rotation.y = (Math.PI / 180) * 5;
    window.cameraTracker.add(window.boardGroup);

    scene.add(window.cameraTracker);

    // Enable shadows in the rednerer.
    renderer.shadowMap.enabled = true;
    // Add some light to the scene.
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1.0);
    directionalLight.position.set(1, 4, 1);
    directionalLight.castShadow = true;

    directionalLight.shadow.mapSize.width = 2048; // default
    directionalLight.shadow.mapSize.height = 2048; // default
    directionalLight.shadow.camera.near = 0.01;
    directionalLight.shadow.camera.far = 100;

    directionalLight.shadow.camera.left = -30;
    directionalLight.shadow.camera.right = 30;
    directionalLight.shadow.camera.top = 30;
    directionalLight.shadow.camera.bottom = -30;

    scene.add(directionalLight);

    //scene.add(new THREE.AmbientLight({ intensity: 0.3 }));

    window.camera.position.set(0, 2, 5);
    window.boardGroup.position.y = 0;
    window.boardGroup.scale.set(0.01, 0.01, 0.01);

    setTimeout(() => {
      window.boardGroup.visible = true;
      gsap.to(window.boardGroup.scale, {
        x: 10,
        y: 10,
        z: 10,
        duration: 0.5,
        ease: "back.out",
      });
    }, 1000);

    // Add HDRI
    new RGBELoader()
      .setPath("./assets/models/")
      .load("venice_sunset_1k.hdr", function (texture) {
        texture.mapping = THREE.EquirectangularReflectionMapping;
        scene.environment = texture;
        window.envMap = texture;

        // add the env map again
        // window.board.model.scene.traverse((node) => {
        //   if (node.material) {
        //     // node.material.envMapIntensity = 0;
        //     node.material.envMap = texture;
        //   }
        // });
      });
  };

  // document.addEventListener("gesturestart", function (e) {
  //   e.preventDefault();
  // });

  const scaleOnPinch = (state) => {
    if (!isPlacingBoard) return;
    window.cameraTracker.scale.setScalar(state.offset[0]);
  };

  const rotateOnPinch = (state) => {
    if (!isPlacingBoard) return;
    window.cameraTracker.rotation.y = (Math.PI / -180) * state.offset[1];
  };

  const moveOnDrag = (e) => {
    if (window.objectPlaced) {
      return;
    }
    let velocityX = (e.touches[0].clientX - window.prevX) / 100;
    let velocityY = (e.touches[0].clientY - window.prevY) / 100;

    window.cameraTracker.position.x += velocityX;
    window.cameraTracker.position.z += velocityY;

    window.prevX = e.touches[0].clientX;
    window.prevY = e.touches[0].clientY;
  };

  // Return a camera pipeline module that adds scene elements on start.
  return {
    // Camera pipeline modules need a name. It can be whatever you want but must be unique within
    // your app.
    name: "cubethreejs",

    // comment  this if it breaks things
    // onStart,
    // onRender,
    // onCanvasSizeChange: ({ canvasWidth, canvasHeight }) => {
    //   const stage3D = window.XR8.Threejs.xrScene();
    //   stage3D.resize(canvasWidth, canvasHeight);
    //   stage3D.post?.composer?.setSize(canvasWidth, canvasHeight);
    // },

    // onStart is called once when the camera feed begins. In this case, we need to wait for the
    // XR8.Threejs scene to be ready before we can access it to add content. It was created in
    // XR8.Threejs.pipelineModule()'s onStart method.
    onAttach: ({ canvas }) => {
      const { scene, camera, renderer } = window.XR8.Threejs.xrScene(); // Get the 3js scene from XR8.Threejs
      window.canvasRef = canvas;
      window.cameraRef = camera;
      window.rendererRef = renderer;
      // TODO Note: If try increasing pixel density, the AR scene gets jittery
      // renderer.setPixelRatio(window.devicePixelRatio || 1);
      camera.far = 100;
      // camera.aspect = window.innerWidth / window.innerHeight;
      // camera.updateProjectionMatrix();
      // renderer.setSize(window.innerWidth, window.innerHeight);
      initXrScene({ scene, camera, renderer }); // Add objects set the starting camera position.

      // Sync the xr controller's 6DoF position and camera paremeters with our scene.
      window.XR8.XrController.updateCameraProjectionMatrix({
        origin: camera.position,
        facing: camera.quaternion,
      });

      const gesture = new Gesture(
        canvas,
        {
          onPinch: (state) => {
            // console.log("pinch", state);
            scaleOnPinch(state);
            rotateOnPinch(state);
          },
          onDrag: (state) => {
            // console.log("drag", state, state.swipe);
            // if(window.playerManager.isActive && !PlayerManager.isPaused && !window.playerManager.isPowerUpActive){
            //   if(!PlayerManager.isDown){
            //     if(state.swipe[1]== 1 && state.swipe[0]== 0){
            //       PlayerManager.isDown = true
            //       // window.pogba.anim.Run.stopFading()
            //       if(window.changeLaneTimeout){
            //         clearTimeout(window.changeLaneTimeout);
            //       }
            //       fadeOneLoopAnimation(window.pogba.anim['Run'],0.2,window.pogba.anim['Slide'],0.15)
            //     }
            //   }
            // }
          },
        },
        {
          pinch: { scaleBounds: { min: 0.5, max: 5.0 } },
          drag: {
            swipe: { distance: [150, 20], duration: 400, velocity: [0.3, 0.2] },
          },
        }
      );

      // Recenter content when the canvas is tapped.
      // canvas.addEventListener(
      //   "touchstart",
      //   (e) => {
      //     e.touches.length === 1 && window.XR8.XrController.recenter();
      //   },
      //   true
      // );

      canvas.addEventListener("touchstart", (event) => {
        window.prevX = event.touches[0].clientX;
        window.prevY = event.touches[0].clientY;
        if (event.touches.length >= 2) {
          window.pinching = true;
        }
      });

      canvas.addEventListener("touchend", (event) => {
        if (event.touches.length == 0) {
          window.pinching = false;
        }
      });

      // prevent scroll/pinch gestures on canvas
      canvas.addEventListener("touchmove", (event) => {
        // event.preventDefault()
        if (!window.pinching && isPlacingBoard) {
          moveOnDrag(event);
        }
      });
    },

    onUpdate: ({ frameStartResult, processGpuResult, processCpuResult }) => {
      // TODO: add any mixer.update() or frame anaimtion code here!

      var delta = window.clock.getDelta();
      var time = window.clock.getElapsedTime();

      window.models.forEach((m, i) => {
        if (m.mixer) m.mixer.update(delta);
      });

      if (car.mixer) {
        car.animations["Car_Path"].time = car.progress * 11.633333206176758; // path duration
      }

      // money
      // TODO: Note, for the confetti, scale from 0 to 1 :)
      if (moneyState === "start") {
        moneyMesh.visible = true;
        moneyMesh2.visible = true;
        gsap.fromTo(
          fadeAnim,
          { scale: 1, opacity: 0 },
          {
            scale: 1,
            opacity: 1,
            duration: 0.5,
            onComplete: () => {
              moneyState = "playing";
            },
          }
        );
        moneyState = "fadeIn";
      }

      if (moneyState === "stop") {
        gsap.fromTo(
          fadeAnim,
          { opacity: 1 },
          {
            opacity: 0,
            duration: 0.5,
            onComplete: () => {
              moneyState = "hidden";
              moneyMesh.visible = false;
              moneyMesh2.visible = false;
            },
          }
        );
        moneyState = "fadeOut";
      }

      if (moneyState === "fadeIn") {
        moneyMesh.scale.set(fadeAnim.scale, fadeAnim.scale, fadeAnim.scale);
      }
      if (moneyState === "fadeOut") {
        moneyMesh.scale.set(fadeAnim.scale, fadeAnim.scale, fadeAnim.scale);
      }

      if (
        moneyState === "fadeIn" ||
        moneyState === "fadeOut" ||
        moneyState === "playing"
      ) {
        for (let i = 0; i < moneyCount; i++) {
          const p = moneyParticles[i];
          p.rotation.z += delta * 0.6;
          p.rotation.x -= delta * 1.2;
          p.rotation.y += delta * 0.07;
          p.position.y -= delta * moneyFallSpeed;

          if (p.position.y <= -0.05) {
            p.position.y = 2.0 + Math.random() * 2;
          }

          moneyDummy.scale.set(
            p.scale.x * fadeAnim.opacity,
            p.scale.y * fadeAnim.opacity,
            p.scale.z * fadeAnim.opacity
          );

          moneyDummy.position.set(p.position.x, p.position.y, p.position.z);
          moneyDummy.rotation.set(p.rotation.x, p.rotation.y, p.rotation.z);
          //moneyDummy.scale.set(p.scale.x, p.scale.y, p.scale.z);
          moneyDummy.updateMatrix();
          moneyMesh.setMatrixAt(i, moneyDummy.matrix);

          // set 2
          const p2 = moneyParticles2[i];
          p2.rotation.z += delta * 0.6;
          p2.rotation.x -= delta * 1.2;
          p2.rotation.y += delta * 0.02;
          p2.position.y -= delta * moneyFallSpeed;

          if (p2.position.y <= -0.05) {
            p2.position.y = 2.0 + Math.random() * 2;
          }

          moneyDummy2.scale.set(
            p2.scale.x * fadeAnim.opacity,
            p2.scale.y * fadeAnim.opacity,
            p2.scale.z * fadeAnim.opacity
          );

          moneyDummy2.position.set(p2.position.x, p2.position.y, p2.position.z);
          moneyDummy2.rotation.set(p2.rotation.x, p2.rotation.y, p2.rotation.z);
          //moneyDummy.scale.set(p.scale.x, p.scale.y, p.scale.z);
          moneyDummy2.updateMatrix();
          moneyMesh2.setMatrixAt(i, moneyDummy2.matrix);
        }
        moneyMesh.instanceMatrix.needsUpdate = true;
        moneyMesh.computeBoundingSphere();
        moneyMesh2.instanceMatrix.needsUpdate = true;
        moneyMesh2.computeBoundingSphere();
      }
      //

      // confetti
      // TODO: Note, for the confetti, scale from 0 to 1 :)
      if (pState === "start") {
        particleMesh1.visible = true;
        gsap.fromTo(
          [fadeAnimp1, fadeAnimp2, fadeAnimp3],
          { scale: 0, opacity: 0 },
          {
            stagger: 0.5,
            scale: 1.0,
            opacity: 1,
            duration: 0.8,
            // ease: "circ.in",
            onComplete: () => {
              pState = "playing";
            },
          }
        );
        pState = "fadeIn";
      }

      if (pState === "stop") {
        gsap.fromTo(
          [fadeAnimp1, fadeAnimp2, fadeAnimp3],
          { opacity: 1 },
          {
            opacity: 0,
            duration: 0.5,
            onComplete: () => {
              pState = "hidden";
              particleMesh1.visible = false;
            },
          }
        );
        pState = "fadeOut";
      }

      if (pState === "fadeIn") {
        particleMesh1.scale.set(
          fadeAnimp1.scale,
          fadeAnimp1.scale,
          fadeAnimp1.scale
        );
      }

      if (pState === "fadeOut") {
        particleMesh1.scale.set(
          fadeAnimp1.scale,
          fadeAnimp1.scale,
          fadeAnimp1.scale
        );
      }

      if (pState === "fadeIn" || pState === "fadeOut" || pState === "playing") {
        for (let i = 0; i < pCount; i++) {
          const p = particlesArray[i];
          p.rotation.z += delta * 1.6;
          p.rotation.x -= delta * 1.2;
          p.rotation.y += delta * 2.6 * p.random;
          p.position.y -= delta * particleFallSpeed;

          if (p.position.y < 0.1) {
            p.position.y += 3.0;
          }

          particleDummy.scale.set(
            p.scale.x * fadeAnimp1.opacity,
            p.scale.y * fadeAnimp1.opacity,
            p.scale.z * fadeAnimp1.opacity
          );

          particleDummy.position.set(p.position.x, p.position.y, p.position.z);
          particleDummy.rotation.set(p.rotation.x, p.rotation.y, p.rotation.z);

          if (i > 5000) {
            particleDummy.position.set(
              p.position.x * fadeAnimp2.scale,
              p.position.y * fadeAnimp2.scale,
              p.position.z * fadeAnimp2.scale
            );
          }

          if (i > 10000) {
            particleDummy.position.set(
              p.position.x * fadeAnimp3.scale,
              p.position.y * fadeAnimp3.scale,
              p.position.z * fadeAnimp3.scale
            );
          }

          //moneyDummy.scale.set(p.scale.x, p.scale.y, p.scale.z);
          particleDummy.updateMatrix();
          particleMesh1.setMatrixAt(i, particleDummy.matrix);
        }
        particleMesh1.instanceMatrix.needsUpdate = true;
        particleMesh1.computeBoundingSphere();
      }
      //
    },

    listeners: [
      //{event: 'reality.imagescanning', process: constructGeometry},
      // { event: "reality.imagefound", process: showTarget },
      // { event: "reality.imageupdated", process: showTarget },
      // { event: "reality.imagelost", process: hideTarget },
    ],
  };
};
export { gameThreejsPipelineModule };
