import "./InstructionsUI.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../../data/data";
import useStore from "../../../store";
import gsap from "gsap";
// import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";

function InstructionsUI(props) {
  const setPlaySound = useStore((state) => state.setPlaySound);
  const appState = useStore((state) => state.appState);
  //const setAppState = useStore((state) => state.setAppState);
  const gameState = useStore((state) => state.gameState);
  const setGameState = useStore((state) => state.setGameState);
  const showInstructions = useStore((state) => state.showInstructions);
  const setShowInstructions = useStore((state) => state.setShowInstructions);
  const setShowInstructionsButton = useStore(
    (state) => state.setShowInstructionsButton
  );
  const showInstructionsButton = useStore(
    (state) => state.showInstructionsButton
  );

  const el = useRef();
  const instructionsCard = useRef();
  const tl = useRef();

  useEffect(() => {
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline();
    if (showInstructions) {
      console.log("show instructions");
      //show instrucitions
      tl.current.set(el.current, { autoAlpha: 1, zIndex: 3100 });
      tl.current.fromTo(
        [instructionsCard.current],
        { y: 500, autoAlpha: 0 },
        {
          autoAlpha: 1,
          y: 0,
          duration: ".5",
          ease: "back.out",
        }
      );
    } else {
      //hide the place board UI
      console.log("hide instrucitons");
      tl.current.to([instructionsCard.current], {
        autoAlpha: 0,
        y: 500,
        ease: "back.in",
      });
      tl.current.set(el.current, { autoAlpha: 0, zIndex: -1 });
    }
  }, [showInstructions]);

  const showInstructionsClicked = () => {
    //setShowInstructionsButton(false);
    setPlaySound("click");
    setShowInstructions(!showInstructions);
  };

  return (
    <div ref={el} className="instructions">
      <div ref={instructionsCard} className="instructions-card">
        <button
          onClick={showInstructionsClicked}
          className="close-instructions-button"
          id="instructions-card-button"
        >
          <img src="../../assets/images/close.svg" alt="close" />
        </button>
        <h1 className="title" data-text="How To Play">
          How To Play
        </h1>
        <div className="rows">
          <div className="row">
            <img
              src="../../assets/images/instructions-card-icon-1.png"
              alt=""
            />
            <p className="text">
              Tap your screen to roll the dice, then get rolling as your car
              moves across the board.
            </p>
          </div>
          <div className="row">
            <img
              src="../../assets/images/instructions-card-icon-2.png"
              alt=""
            />
            <p className="text">
              Along your journey are hidden surprises and snacks to collect, so
              keep your eyes peeled!
            </p>
          </div>
          <div className="row">
            <img
              src="../../assets/images/instructions-card-icon-3.png"
              alt=""
            />
            <p className="text">
              Reach the finish line for a chance to win a $1000 Game Chest!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InstructionsUI;
