import "./Overlay.scss";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import useStore from "../../store";

function Overlay(props) {
  const appState = useStore((state) => state.appState);
  const showAgeGate = useStore((state) => state.showAgeGate);
  const showPermissionError = useStore((state) => state.showPermissionError);
  const showInstructions = useStore((state) => state.showInstructions);

  const el = useRef();
  const tl = useRef();

  useEffect(() => {
    if (!appState) return;
    if (tl.current) tl.current.pause();
    if (
      showAgeGate ||
      appState === "ageGateFailed" ||
      showPermissionError ||
      showInstructions
    ) {
      tl.current = gsap.timeline({ delay: 0.0 });
      tl.current.set(el.current, { autoAlpha: 1, zIndex: 2000 });
      tl.current.to(el.current, {
        "-webkit-backdrop-filter": "blur(1px) brightness(.3)",
        backdropFilter: "blur(1px) brightness(.3)",
        autoAlpha: 1,
        duration: 1,
      });
    } else {
      tl.current = gsap.timeline({ delay: 0.0 });
      tl.current.to(el.current, {
        "-webkit-backdrop-filter": "blur(0)",
        backdropFilter: "blur(0) brightness(1)",
        autoAlpha: 0,
        duration: 0.5,
      });
      tl.current.set(el.current, { zIndex: -1 });
    }
  }, [appState, showAgeGate, showPermissionError, showInstructions]);

  return <div ref={el} className={`overlay`}></div>;
}

export default Overlay;
