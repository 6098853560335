import "./FinalUI.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../../data/data";
import useStore from "../../../store";
import gsap from "gsap";
// import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";

function FinalUI(props) {
  const appState = useStore((state) => state.appState);
  //const setAppState = useStore((state) => state.setAppState);
  const gameState = useStore((state) => state.gameState);
  const setGameState = useStore((state) => state.setGameState);
  const currentZone = useStore((state) => state.currentZone);

  const setPlaySound = useStore((state) => state.setPlaySound);

  const el = useRef();
  const tl = useRef();

  const icon = useRef();
  const title = useRef();

  useEffect(() => {
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline({ delay: 2 });
    if (currentZone === "end") {
      //show title
      tl.current.set(el.current, { zIndex: 2100, autoAlpha: 1 });
      tl.current.fromTo(
        [icon.current, title.current],
        { y: 300, scale: 0.5, autoAlpha: 0 },
        {
          stagger: 0.2,
          autoAlpha: 1,
          y: 0,
          scale: 1,
          duration: 0.5,
          ease: "back.out",
        }
      );
      tl.current.to([icon.current, title.current], {
        y: 100,
        scale: 0.5,
        stagger: 0.2,
        autoAlpha: 0,
        duration: 0.3,
        ease: "back.in",
        delay: 3,
      });
      tl.current.set(el.current, { autoAlpha: 0, zIndex: -1 });
      tl.current.call(() => {
        setGameState("end");
      });
    }
  }, [currentZone]);

  return (
    <div ref={el} id="final-ui" className="final-ui">
      <img
        ref={icon}
        className="icon"
        src="../../assets/images/final-ui.png"
        alt=""
      />
      <h1 ref={title} data-text="You Made It!" className="title">
        You Made It!
      </h1>
    </div>
  );
}

export default FinalUI;
