import "./Landing.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../data/data";
import gsap from "gsap";
import useStore from "../../store";
import MiniLoadingBar from "../MiniLoadingBar/MiniLoadingBar";
// import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";

function Landing(props) {
  const appState = useStore((state) => state.appState);
  const setAppState = useStore((state) => state.setAppState);
  const setGameState = useStore((state) => state.setGameState);
  const setIsPlacingBoard = useStore((state) => state.setIsPlacingBoard);
  const setIsARActive = useStore((state) => state.setIsARActive);
  const isARActive = useStore((state) => state.isARActive);
  const setShowAgeGate = useStore((state) => state.setShowAgeGate);
  const showLoader = useStore((state) => state.showLoader);
  const setPlaySound = useStore((state) => state.setPlaySound);

  const el = useRef();
  const tl = useRef();
  const title = useRef();
  const text = useRef();
  const eighthWallLogo = useRef();
  const fritoLogo = useRef();
  const hasbroLogo = useRef();
  const verify = useRef();
  const verifyBlock = useRef();
  const startButton = useRef();
  const troubleButton = useRef();
  const legal = useRef();
  const checkError = useRef();

  useEffect(() => {
    // localStorage.removeItem("passedAgeGate"); // for testing age gate
    // check if we need to show this or show failed again
    const passedAgeGate = localStorage.getItem("passedAgeGate");

    console.log("passed age gate", passedAgeGate);

    if (passedAgeGate && passedAgeGate === "true") {
      setShowAgeGate(false);
      return;
    }

    if (passedAgeGate && passedAgeGate === "false") {
      setShowAgeGate(false);
      setAppState("ageGateFailed");
      return;
    }

    if (!passedAgeGate) {
      setShowAgeGate(true);
      return;
    }
  }, []);

  useEffect(() => {
    // intro
    if (tl.current) tl.current.pause();
    if (appState === "landing") {
      //setShowAgeGate(true);
      tl.current = gsap.timeline({ delay: 0.5 });
      tl.current.set(el.current, { autoAlpha: 1, zIndex: 1100 });
      tl.current.set(checkError.current, { autoAlpha: 0 });
      tl.current.fromTo(
        [title.current, text.current],
        { autoAlpha: 0, y: 80, scale: 1.2 },
        { stagger: 0.2, scale: 1, autoAlpha: 1, y: 0, duration: 1 }
      );
      tl.current.fromTo(
        [fritoLogo.current, hasbroLogo.current],
        { autoAlpha: 0, y: 80, scale: 1.05 },
        { scale: 1, autoAlpha: 1, y: 0, duration: 0.5, ease: "back.out" },
        0.7
      );
      tl.current.fromTo(
        [
          verifyBlock.current,
          startButton.current,
          troubleButton.current,
          legal.current,
        ],
        { autoAlpha: 0, y: 160, scale: 1 },
        {
          stagger: 0.1,
          scale: 1,
          autoAlpha: 1,
          y: 0,
          duration: 0.5,
          ease: "sine.out",
        },
        0.5
      );
      tl.current.fromTo(
        [eighthWallLogo.current],
        { autoAlpha: 0, y: -80, scale: 1 },
        {
          stagger: 0.1,
          scale: 1,
          autoAlpha: 1,
          y: 0,
          duration: 0.5,
          ease: "sine.out",
        },
        0.7
      );
    } else {
      tl.current = gsap.timeline({});
      tl.current.to(
        [
          eighthWallLogo.current,
          fritoLogo.current,
          hasbroLogo.current,
          title.current,
          text.current,
          checkError.current,
          verifyBlock.current,
          startButton.current,
          troubleButton.current,
          legal.current,
        ],
        {
          stagger: 0.03,
          autoAlpha: 0,
          y: 0,
          duration: 0.25,
        }
      );
      tl.current.set(el.current, { autoAlpha: 0, zIndex: -1 });
    }
  }, [appState]);

  const termsClicked = () => {
    if (isARActive) return;
    // setAppState("terms");
    setPlaySound("click");
    window.open("https://contact.pepsico.com/FritoLay/terms-conditions");
  };

  const privacyClicked = () => {
    if (isARActive) return;
    // setAppState("terms");
    setPlaySound("click");
    window.open("https://contact.pepsico.com/FritoLay/privacy-policy");
  };

  const rulesClicked = () => {
    if (isARActive) return;
    setAppState("rules");
    setPlaySound("click");
  };

  const troubleClicked = () => {
    setAppState("accessible");
    setGameState("accessible");
    setPlaySound("click");
  };

  const startButtonClicked = () => {
    if (isARActive) return;
    setPlaySound("main loop");
    if (!verify.current.checked) {
      gsap.fromTo(
        checkError.current,
        { autoAlpha: 0, y: 30 },
        { autoAlpha: 1, y: 0, duration: 0.3 }
      );
      return;
    }
    gsap.to(checkError.current, { autoAlpha: 0, y: 30, duration: 0.3 });
    setIsARActive(true);

    // setIsPlacingBoard(true);
  };

  return (
    <div ref={el} className={`landing`}>
      <img
        className="eighthwall-logo"
        ref={eighthWallLogo}
        src="../../assets/images/8thwall-logo.png"
        alt=""
      />
      <div className="center-group">
        <div className="logos">
          <img
            className="frito-logo"
            ref={fritoLogo}
            src="../../assets/images/frito-logo.png"
            alt=""
          />
          <img
            className="hasbro-logo"
            ref={hasbroLogo}
            src="../../assets/images/hasbro-logo.png"
            alt=""
          />
        </div>
        <img
          className="titles"
          ref={title}
          src="../../assets/images/titles.png"
          alt=""
        />
        <p ref={text} className="text">
          You could win a $1000
          <br />
          Game Chest every day!
          <br />
          Are you game?
        </p>
      </div>
      <div className="bottom-group">
        <img
          ref={checkError}
          className="check-error"
          src={"../../assets/images/check-error.png"}
          alt=""
        />
        <div ref={verifyBlock} className="verify-container">
          <input
            ref={verify}
            id="confirm-checkbox"
            className="checkbox"
            type={"checkbox"}
            onClick={() => {
              setPlaySound("click");
            }}
          />
          <p>
            By checking this box, I state that I have read and understood{" "}
            <span onClick={rulesClicked} id="rules-link" className="link">
              Game Rules
            </span>{" "}
            and allow the use of cookies. Results of gameplay does not affect
            the type of prize won.
          </p>
        </div>

        <button
          ref={startButton}
          className="start-button"
          onClick={startButtonClicked}
          disabled={showLoader}
          id="start-button"
        >
          <img
            className="start-button-image"
            src="../../assets/images/start-button.png"
            alt="start"
          />
          <div className="mini-bar-wrapper">
            <MiniLoadingBar />
          </div>
        </button>
        <button
          ref={troubleButton}
          id="trouble-viewing-button"
          className="trouble-viewing-button"
          aria-label="Having Trouble Viewing?"
          onClick={troubleClicked}
        >
          Having Trouble Viewing?
        </button>
        <p ref={legal} className="legal">
          NO PURCHASE OR SCAN NECESSARY TO PLAY OR WIN. Open only to legal
          residents of the 50 US/DC, 18+ (19+ for AL/NE) at time of playing.{" "}
          <span onClick={termsClicked} id="terms-link" className="link">
            Terms and Conditions
          </span>{" "}
          |{" "}
          <span onClick={privacyClicked} id="privacy-link" className="link">
            Privacy Policy
          </span>
        </p>
      </div>
      {/* <button
        onClick={() => {
          localStorage.removeItem("passedAgeGate");
          // clear anything else we stoted
          window.location.reload();

          // setShowInstructions(true);
        }}
        className="clear"
      >
        Reset Game
      </button> */}
    </div>
  );
}

export default Landing;
