import "./Rotate.scss";
import { useEffect, useRef } from "react";
import gsap from "gsap";

import useStore from "../../store";

function Rotate(props) {
  const appState = useStore((state) => state.appState);

  const tl = useRef();
  const el = useRef();
  const titleLockUpRef = useRef();
  const backgroundRef = useRef();
  const rotateRef = useRef();

  useEffect(() => {
    // TAdd anythign ehere we might want todo if rotated
  }, [appState]);

  return (
    <div ref={el} className={`rotate`}>
      {/* <img ref={backgroundRef} src={backgound} alt="" className="background" />
      <img ref={titleLockUpRef} src={titleLockUp} alt="" className="logo" /> */}
      <img
        ref={rotateRef}
        src={"../../assets/images/rotate.png"}
        alt=""
        className="rotate-image"
      />
    </div>
  );
}

export default Rotate;
