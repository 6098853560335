import "./ARScene.scss";
import { gameThreejsPipelineModule } from "./gameThreejsPipelineModule";
import { Fragment, useEffect, useRef } from "react";
import Lottie from "react-lottie-player";
import loaderJSON from "../../assets/animations/loader.json";
import eighthWallLogo from "../../assets/images/8th-wall-logo.svg";

import useStore from "../../store";
import * as THREE from "three";
import gsap from "gsap";
window.THREE = THREE;

//TODO: Add flag to Lottie loader anaimtion to stop it when not needed.

function ARScene(props) {
  const isARActive = useStore((state) => state.isARActive);
  const isARInFocus = useStore((state) => state.isARInFocus);
  const setShowPermissionError = useStore(
    (state) => state.setShowPermissionError
  );
  const setAppState = useStore((state) => state.setAppState);
  const appState = useStore((state) => state.appState);
  const setIsPlacingBoard = useStore((state) => state.setIsPlacingBoard);

  const showLoader = useStore((state) => state.showLoader);

  const tl = useRef();
  const tl2 = useRef();
  const canvasRef = useRef();
  const eighthWallLogoRef = useRef();

  const onxrloaded = () => {
    // window.XR8.XrController.configure({ imageTargets: [] }); // Disable default image targets.
    // // LandingPage.configure({
    // //   mediaSrc: "https://media.giphy.com/media/UIQc7mECaH5nw0Y03Y/giphy.mp4",
    // // });
    //console.log(window.XRExtras.FullWindowCanvas);
    window.XR8.addCameraPipelineModules([
      window.XR8.GlTextureRenderer.pipelineModule(), // Draws the camera feed.
      window.XR8.Threejs.pipelineModule(), // Creates a ThreeJS AR Scene.
      window.XR8.XrController.pipelineModule(), // Enables SLAM tracking.
      // LandingPage.pipelineModule(), // Detects unsupported browsers and gives hints.
      window.XRExtras.FullWindowCanvas.pipelineModule(), // Modifies the canvas to fill the window.
      //window.XRExtras.Loading.pipelineModule(), // Manages the loading screen on startup.
      window.XRExtras.RuntimeError.pipelineModule(), // Shows an error image on runtime error.
      // Custom pipeline modules.
      gameThreejsPipelineModule(),

      // Handle Errors, Permissions or Camera disconnect
      {
        name: "customCameraErrorHandle",
        onStart: () => {
          // andoird and older iOS dont need this, so check if exists first
          if (
            typeof window.DeviceOrientationEvent !== "undefined" &&
            typeof DeviceOrientationEvent["requestPermission"] === "function"
          ) {
            // iOS 13 + and device orientation is required permission
            DeviceOrientationEvent.requestPermission()
              .then((response) => {
                if (response !== "granted") {
                  setShowPermissionError(true);
                  return;
                }
              })
              .catch((error) => {
                setShowPermissionError(true);
                return;
              });
          }

          if (tl.current) tl.current.pause();
          tl.current = gsap.timeline();
          tl.current.call(() => {
            setAppState("game");
          });
          tl.current.call(
            () => {
              setIsPlacingBoard(true);
            },
            null,
            1
          );
        },
        onCameraStatusChange: ({ status }) => {
          console.log(status);
          if (status === "failed") {
            setShowPermissionError(true);
          }
        },
        onException: (error) => {
          console.log(error);
          if (
            error.type === "permission" &&
            (error.permission === "prompt" ||
              error.permission ===
                window.XR8.XrPermissions.permissions().DEVICE_ORIENTATION)
          ) {
            setShowPermissionError(true);
            return;
          }
        },
      },
    ]);
    window.XR8.run({ canvas: canvasRef.current, verbose: true });
    // Cleanup
    return () => {
      window.XR8.stop();
      window.XR8.clearCameraPipelineModules();
    };
  };

  useEffect(() => {
    if (appState === "vps") {
      if (window.XR8 && !window.XR8.isPaused()) window.XR8.pause();
    } else {
      if (window.XR8 && window.XR8.isPaused()) window.XR8.resume();
    }

    if (appState === "game") {
      if (tl2.current) tl2.current.pause();
      tl2.current = gsap.timeline();
      tl2.current.to(canvasRef.current, {
        autoAlpha: 1,
        duration: 0.5,
      });
    }
    // if (
    //   appState === "instructions" ||
    //   appState === "ageGate" ||
    //   appState === "failedAgeGate"
    // ) {
    //   eighthWallLogoRef.current.style.display = "none";
    // }
  }, [appState]);

  useEffect(() => {
    //TODO: Do we need to check if we should runthhis more than once???
    if (isARActive) {
      // uncomment below to re enable AR
      window.XR8
        ? onxrloaded()
        : window.addEventListener("xrloaded", onxrloaded);
    }
  }, [isARActive]);

  useEffect(() => {
    if (tl.current) {
      tl.current.pause();
    }
    tl.current = gsap.timeline({});
    if (!isARInFocus) {
      tl.current.to(canvasRef.current, {
        filter: "brightness(.5)",
        duration: 0.5,
      });
    } else {
      tl.current.to(canvasRef.current, {
        filter: "brightness(1)",
        duration: 0.5,
      });
    }
  }, [isARInFocus]);

  return (
    <div className="wrapper">
      {/* <div className="loading">
        <div className="lottie-wrapper">
          {showLoader && appState === "game" && (
            <Lottie
              loop
              animationData={loaderJSON}
              play
              style={{ width: 100, height: 100 }}
            />
          )}
        </div>
       
      </div> */}

      <canvas
        style={{
          position: "absolute",
          zIndex: 1,
          // pointerEvents: "none",
          top: 0,
          left: 0,
          background: "transparent",
          opacity: 0,
          visibility: "hidden",
        }}
        ref={canvasRef}
        id="camerafeed"
      ></canvas>
    </div>
  );
}

export default ARScene;
