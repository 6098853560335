import "./AgeGate.scss";
import { useEffect, useRef, useState } from "react";
import gsap from "gsap";
// import titleLockUp from "../../assets/images/title-lockup.png";
// import ageGateButton from "../../assets/images/age-gate-button-backer.png";
import useStore from "../../store";

function AgeGate(props) {
  const showAgeGate = useStore((state) => state.showAgeGate);
  const setShowAgeGate = useStore((state) => state.setShowAgeGate);
  const setAppState = useStore((state) => state.setAppState);
  const setPlaySound = useStore((state) => state.setPlaySound);

  const tl = useRef();
  const el = useRef();
  // const titleLockUpRef = useRef();
  // const confirmRef = useRef();
  const monthRef = useRef();
  const yearRef = useRef();

  const [disabled, setDisabled] = useState(true);
  const [month, setMonth] = useState("MM");
  const [year, setYear] = useState("YYYY");

  useEffect(() => {
    console.log("show age gate", showAgeGate);
    // TODO: Aniamte in assets cooler later
    if (tl.current) tl.current.pause();
    if (showAgeGate) {
      tl.current = gsap.timeline({ delay: 0.5 });
      tl.current.set(el.current, { y: 500, zIndex: 2100, autoAlpha: 1 });
      tl.current.to(el.current, {
        y: 0,

        duration: 0.5,
        ease: "back.out",
      });
    } else {
      tl.current = gsap.timeline({ delay: 0 });
      tl.current.to(el.current, {
        y: 500,
        autoAlpha: 0,
        duration: 0.5,
        ease: "back.in",
      });
      tl.current.set(el.current, { zIndex: 0 });
    }
  }, [showAgeGate]);

  const months = [
    { value: "1", label: "01" },
    { value: "2", label: "02" },
    { value: "3", label: "03" },
    { value: "4", label: "04" },
    { value: "5", label: "05" },
    { value: "6", label: "06" },
    { value: "7", label: "07" },
    { value: "8", label: "08" },
    { value: "9", label: "09" },
    { value: "10", label: "10" },
    { value: "11", label: "11" },
    { value: "12", label: "12" },
  ];

  const years = Array.from({ length: (2023 - 1953) / 1 + 1 }, (_, i) => {
    return { value: 2023 - i, label: 2023 - i };
  });

  const checkDisabled = () => {
    if (monthRef.current.value)
      setMonth(
        monthRef.current.value < 10
          ? "0" + monthRef.current.value
          : monthRef.current.value
      );
    if (yearRef.current.value) setYear(yearRef.current.value);

    setDisabled(
      !(
        // confirmRef.current.checked &&
        (yearRef.current.value && monthRef.current.value)
      )
    );
  };

  const formSubmit = (e) => {
    setPlaySound("click");
    e.preventDefault();

    //validate form
    if (!(yearRef.current.value && monthRef.current.value)) return;

    if (!monthRef.current.value || !yearRef.current.value) return;
    const age =
      new Date(
        new Date() -
          new Date(
            yearRef.current.value +
              "/" +
              String(parseInt(monthRef.current.value)).padStart(2, "0") +
              "/01"
          )
      ).getFullYear() - 1970;

    if (age < 18) {
      //console.log("failed age gate");
      setShowAgeGate(false);
      setAppState("ageGateFailed");
      localStorage.setItem("passedAgeGate", "false");
    } else {
      //console.log("passed age gate");
      setShowAgeGate(false);
      setAppState("landing");
      localStorage.setItem("passedAgeGate", "true");
    }

    return;
  };

  return (
    <div ref={el} className={`age-gate`}>
      <form className="form" onSubmit={formSubmit}>
        <img
          className="icon"
          src="../../assets/images/age-gate-icon.png"
          alt=""
        />
        <h2 className="text">Enter your month and year of birth:</h2>
        <div className="date">
          <div className="select-wrapper">
            <select
              // aria-label={ageGate.ariaLabelSelectMonth}
              ref={monthRef}
              defaultValue=""
              onChange={checkDisabled}
              id="age-gate-month"
            >
              <option value="" disabled hidden>
                MM
              </option>
              {months.map((m, i) => {
                return (
                  <option key={"month_" + i} value={m.value}>
                    {m.label}
                  </option>
                );
              })}
            </select>
            <div className="month-text date-text" data-text={month}>
              {month}
            </div>
          </div>

          <div className="divider"></div>
          <div className="select-wrapper">
            <select
              id="age-gate-year"
              ref={yearRef}
              defaultValue=""
              onChange={checkDisabled}
            >
              <option value="" disabled hidden>
                YYYY
              </option>
              {years.map((y, i) => {
                return (
                  <option key={"year_" + i} value={y.value}>
                    {y.label}
                  </option>
                );
              })}
            </select>
            <div className="year-text date-text" data-text={year}>
              {year}
            </div>
          </div>
        </div>
        <button
          disabled={disabled}
          onClick={formSubmit}
          className="submit-button"
          id="submit-button"
        >
          <img src="../../assets/images/submit-button.png" alt="submit" />
        </button>
      </form>
    </div>
  );
}

export default AgeGate;
