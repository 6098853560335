import "./Background.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../data/data";
import gsap from "gsap";
import useStore from "../../store";
// import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";

function Background(props) {
  const appState = useStore((state) => state.appState);
  //const setAppState = useStore((state) => state.setAppState);

  const el = useRef();
  const tl = useRef();
  const bottom = useRef();
  const cloud1 = useRef();
  const cloud2 = useRef();
  const cloud3 = useRef();
  const cloud4 = useRef();
  const moon = useRef();
  const overlay = useRef();

  useEffect(() => {
    // intro
    if (tl.current) tl.current.pause();
    if (
      appState === "" ||
      appState === "landing" ||
      appState === "ageGateFailed"
    ) {
      tl.current = gsap.timeline({ delay: 0.5 });
      tl.current.set(el.current, { autoAlpha: 1, zIndex: 0 });
      tl.current.fromTo(
        [bottom.current, overlay.current],
        { autoAlpha: 0, y: 150, scale: 1.3 },
        {
          stagger: 0.5,
          scale: 1.0,
          autoAlpha: 1,
          y: 0,
          duration: 1,
          // ease: "sine.out",
        }
      );

      tl.current.fromTo(
        [cloud2.current, cloud3.current],
        { autoAlpha: 0, x: 100, scale: 1.1 },
        {
          stagger: 0.5,
          scale: 1.0,
          autoAlpha: 1,
          y: 0,
          x: 0,
          duration: 1.7,
        },
        0.0
      );
      tl.current.fromTo(
        [cloud1.current, cloud4.current],
        { autoAlpha: 0, x: -100, scale: 1.1 },
        {
          stagger: 0.5,
          scale: 1.0,
          autoAlpha: 1,
          y: 0,
          x: 0,
          duration: 1.5,
        },
        0.0
      );
      tl.current.fromTo(
        moon.current,
        { autoAlpha: 0, y: -100, x: 50, scale: 1.6 },
        {
          scale: 1.0,
          autoAlpha: 1,
          y: 0,
          x: 0,
          duration: 1.5,
          // ease: "sine.out",
        },
        0.1
      );
      tl.current.to(
        [cloud1.current],
        {
          stagger: 0.2,
          scale: 1.1,
          y: -3,
          x: 100,
          duration: 160,
        },
        1.9
      );
      tl.current.to(
        [cloud2.current],
        {
          scale: 0.9,
          y: 10,
          x: -150,
          duration: 140,
        },
        1.9
      );
      tl.current.to(
        [cloud3.current],
        {
          stagger: 0.2,
          scale: 1.1,
          y: 3,
          x: -100,
          duration: 180,
        },
        1.9
      );
      tl.current.to(
        [cloud4.current],
        {
          scale: 1.2,
          y: 40,
          x: 300,
          duration: 230,
        },
        1.9
      );
    }

    if (appState === "game") {
      tl.current = gsap.timeline({});
      tl.current.to(
        [
          overlay.current,
          bottom.current,
          cloud1.current,
          cloud2.current,
          cloud3.current,
          cloud4.current,
          moon.current,
        ],
        {
          stagger: 0.01,
          autoAlpha: 0,
          y: 50,
          scale: 1.1,
          duration: 0.5,
        }
      );
      tl.current.set(el.current, { autoAlpha: 0, zIndex: 0 });
    }
  }, [appState]);

  return (
    <div ref={el} className={`background`}>
      <img
        className="landing-bottom"
        ref={bottom}
        src="../../assets/images/landing-bottom.png"
        alt=""
      />
      <div ref={overlay} className="overlay"></div>
      <img
        className="moon"
        ref={moon}
        src="../../assets/images/moon.png"
        alt=""
      />
      <img
        className="cloud cloud-1"
        ref={cloud1}
        src="../../assets/images/cloud.png"
        alt=""
      />
      <img
        className="cloud cloud-2"
        ref={cloud2}
        src="../../assets/images/cloud.png"
        alt=""
      />
      <img
        className="cloud cloud-3"
        ref={cloud3}
        src="../../assets/images/cloud.png"
        alt=""
      />
      <img
        className="cloud cloud-4"
        ref={cloud4}
        src="../../assets/images/cloud.png"
        alt=""
      />
    </div>
  );
}

export default Background;
