import "./TapToRollUI.scss";
import { useEffect, useRef } from "react";
import parse from "html-react-parser";
import { data } from "../../../data/data";
import useStore from "../../../store";
import gsap from "gsap";
// import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";

function TapToRollUI(props) {
  const setPlaySound = useStore((state) => state.setPlaySound);
  const appState = useStore((state) => state.appState);
  //const setAppState = useStore((state) => state.setAppState);
  const gameState = useStore((state) => state.gameState);
  const currentZone = useStore((state) => state.currentZone);
  const setGameState = useStore((state) => state.setGameState);
  const showInstructions = useStore((state) => state.showInstructions);

  const el = useRef();
  const tl = useRef();

  useEffect(() => {
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline();
    if (
      gameState === "waitingToRoll" &&
      currentZone !== "end" &&
      !showInstructions
    ) {
      //show starting title
      tl.current.set(el.current, { zIndex: 2100 });
      tl.current.fromTo(
        el.current,
        { y: 200, scale: 0.5, autoAlpha: 0 },
        {
          autoAlpha: 1,
          y: 0,
          scale: 1,
          duration: 0.5,
          ease: "back.out",
        }
      );
      // tl.current.call(
      //   () => {
      //     setGameState("waitingToRoll");
      //   },
      //   null,
      //   1.5
      // );
    } else {
      // hide
      //show starting title
      tl.current.to(el.current, {
        y: 200,
        scale: 1,
        autoAlpha: 0,
        duration: 0.3,
        ease: "back.in",
      });
      tl.current.set(el.current, { autoAlpha: 0, zIndex: -1 });
    }
  }, [gameState, showInstructions]);

  // TODO: Fix this so doesnt get stuck, conflict with above..
  // useEffect(() => {
  //   if (showInstructions) {
  //     gsap.to(el.current, { autoAlpha: 0 });
  //   } else {
  //     gsap.to(el.current, { autoAlpha: 1 });
  //   }
  // }, [showInstructions]);

  const roll = () => {
    setPlaySound("click");
    setGameState("rolling");
  };

  return (
    <div ref={el} className="tap-to-roll-ui">
      <h1 data-text="Tap To Roll" className="title">
        Tap To Roll
      </h1>
      <button onClick={roll} id="tap-to-roll-button">
        <img
          className="icon"
          src="../../assets/images/tap-to-roll.png"
          alt="start"
        />
      </button>
    </div>
  );
}

export default TapToRollUI;
