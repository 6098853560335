import { Suspense, useEffect, useRef } from "react";
import "./App.scss";
import Background from "./components/Background/Background";
import Landing from "./components/Landing/Landing";
import ARScene from "./components/ARScene/ARScene";
import GameUI from "./components/GameUI/GameUI";
import DesktopLanding from "./components/DesktopLanding/DesktopLanding";
import Overlay from "./components/Overlay/Overlay";
import AgeGate from "./components/AgeGate/AgeGate";
import AgeGateFailed from "./components/AgeGateFailed/AgeGateFailed";
import Terms from "./components/Terms/Terms";
import Rules from "./components/Rules/Rules";
import PermissionError from "./components/PermissionError/PermissionError";
import ResultsUI from "./components/GameUI/ResultsUI/ResultsUI";
import ClaimUI from "./components/GameUI/ClaimUI/ClaimUI";
import WonUI from "./components/GameUI/WonUI/WonUI";
import LostUI from "./components/GameUI/LostUI/LostUI";
import SoundButton from "./components/GameUI/SoundButton/SoundButton";
import Audio from "./components/Audio/Audio";
import LoadingBar from "./components/LoadingBar/LoadingBar";
import useStore from "./store";
import ComingSoon from "./components/ComingSoon/ComingSoon";
import Rotate from "./components/Rotate/Rotate";
import TagManager from "react-gtm-module";
import AccessbileClaimUI from "./components/GameUI/AccessbileClaimUI/AccessbileClaimUI";

const tagManagerArgs = {
  gtmId: "GTM-TSD97S43",
};

// COMING SOON  - Set appState="comingSoon" in store.js
// Live Site  - Set appState="landing" in store.js

function App() {
  const setAppState = useStore((state) => state.setAppState);
  const appState = useStore((state) => state.appState);
  const comingSoon = useRef(false);

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);
    if (!isMobileDevice) {
      document.body.classList.add("desktop");
    }
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  comingSoon.current = urlParams.get("comingSoon");
  //console.log(vidConOverride);
  if (comingSoon.current === "false") {
    setAppState("landing");
  }

  return (
    <>
      {appState === "comingSoon" && <ComingSoon />}
      {appState !== "comingSoon" && (
        <Suspense fallback={null}>
          <>
            <LoadingBar />
            <Audio />
            <Overlay />
            <PermissionError />
            <AgeGate />
            <AgeGateFailed />
            <Landing />
            <Terms />
            <Rules />
            <Background />
            <ARScene />
            <GameUI />
            <ResultsUI />
            <ClaimUI />
            <AccessbileClaimUI />
            <WonUI />
            <LostUI />
            <SoundButton />
            <Rotate />
            <DesktopLanding />
          </>
        </Suspense>
      )}
    </>
  );
}

export default App;
