import gsap from "gsap";
import "./ComingSoon.scss";
import { useEffect, useRef } from "react";

function ComingSoon(props) {
  const tl = useRef();
  const el = useRef();
  const background = useRef();
  const backgroundMobile = useRef();
  const title = useRef();
  const titleText = useRef();
  const text = useRef();
  const qrCode = useRef();
  const fritoLogo = useRef();
  const hasbroLogo = useRef();

  useEffect(() => {
    tl.current = gsap.timeline({ delay: 1.0 });

    tl.current.fromTo(
      [
        fritoLogo.current,
        hasbroLogo.current,
        title.current,
        titleText.current,
        text.current,
      ],
      { y: 50, autoAlpha: 0 },
      {
        y: 0,

        autoAlpha: 1,
        stagger: 0.1,
        duration: 0.5,
        ease: "sine.out",
      }
    );
  }, []);

  return (
    <div ref={el} className={`coming-soon`}>
      <img
        src={"../../assets/images/desktop-background.jpg"}
        alt=""
        className="background"
        ref={background}
      />
      <img
        src={"../../assets/images/mobile-coming-soon-background.jpg"}
        alt=""
        className="mobile-background"
        ref={backgroundMobile}
      />
      <div className="center-group">
        <div className="logos">
          <img
            className="frito-logo"
            ref={fritoLogo}
            src="../../assets/images/frito-logo.png"
            alt=""
          />
          <img
            className="hasbro-logo"
            ref={hasbroLogo}
            src="../../assets/images/hasbro-logo.png"
            alt=""
          />
        </div>
        <img
          className="titles"
          ref={title}
          src="../../assets/images/titles.png"
          alt=""
        />
        <h1
          ref={titleText}
          className="title-text"
          data-text={"The Game Begins 3.25.24"}
        >
          The Game Begins 3.25.24
        </h1>
        <p className="text" ref={text}>
          You’re early! But roll back in on March 25 to play for your chance at
          the $1,000 Game Chest!
        </p>
      </div>
    </div>
  );
}

export default ComingSoon;
