import "./ImageSeqeuncePlayer.scss";
import { useEffect, useRef, useState } from "react";

const ImageSeqeuncePlayer = ({
  images = [],
  width = 700,
  height = 800,
  frameRate = 24,
  loop,
  paused,
  classes,
  onClick,
  ...props
}) => {
  const canvas = useRef();
  const renderIndex = useRef(0);
  const imageFiles = useRef([]);

  const renderInterval = useRef();

  const pause = useRef();

  const render = () => {
    //console.log("render", renderIndex.current, imageFiles.current.length);
    //console.log(paused);
    if (pause.current) return;
    if (imageFiles.current[renderIndex.current]) {
      const ctx = canvas.current.getContext("2d");
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      const img = imageFiles.current[renderIndex.current];
      const scale = Math.min(
        canvas.current.width / img.width,
        canvas.current.height / img.height
      );
      const x = canvas.current.width / 2 - (img.width / 2) * scale;
      const y = canvas.current.height / 2 - (img.height / 2) * scale;
      ctx.drawImage(img, x, y, img.width * scale, img.height * scale);
      //console.log("draw", imageFiles.current[renderIndex.current]);
      renderIndex.current =
        renderIndex.current + 1 >= imageFiles.current.length
          ? loop
            ? 0
            : imageFiles.current.length
          : renderIndex.current + 1;

      // console.log("render");
    }
  };

  useEffect(() => {
    //console.log("Got request to pause:", paused);
    pause.current = paused;
  }, [paused]);

  useEffect(() => {
    //console.log("mounted");
    //preload images
    imageFiles.current.length = 0;
    imageFiles.current = [];
    renderIndex.current = 0;

    images.forEach((img, i) => {
      const p = new Image();
      p.src = img.filename;
      imageFiles.current[i] = p;
    });

    renderInterval.current = setInterval(render, 1000 / frameRate);

    return () => {
      clearInterval(renderInterval.current);
    };
  }, [images]);

  return (
    <canvas
      ref={canvas}
      width={width}
      height={height}
      className={`image-seqeunce-player ${classes}`}
      onClick={onClick || null}
    />
  );
};

export default ImageSeqeuncePlayer;
