import "./RollUI.scss";
import { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import { data } from "../../../data/data";
import useStore from "../../../store";
import gsap from "gsap";
import ImageSeqeuncePlayer from "../../ImageSeqeuncePlayer/ImageSeqeuncePlayer";
// import { ReactComponent as ArrowIcon } from "../../assets/images/landing-arrow-circle.svg";

function RollUI(props) {
  const setPlaySound = useStore((state) => state.setPlaySound);
  const appState = useStore((state) => state.appState);
  //const setAppState = useStore((state) => state.setAppState);
  const gameState = useStore((state) => state.gameState);
  const setGameState = useStore((state) => state.setGameState);
  const rollNumber = useStore((state) => state.rollNumber);
  const setRollNumber = useStore((state) => state.setRollNumber);

  const el = useRef();
  const tl = useRef();
  const numberText = useRef();

  const [images, setImages] = useState();

  const [paused, setPaused] = useState(true);

  useEffect(() => {
    let _images = [];

    for (let j = 1; j < 7; j++) {
      _images[j] = [];
      for (let i = 0; i < 30; i++) {
        _images[j].push({
          filename:
            "../../assets/images/dice/0" +
            j +
            "/0" +
            j +
            "_DiceRoll" +
            (i < 10 ? "0" + i : i) +
            ".png",
        });
      }
    }
    setImages(_images);
    console.log(_images);
  }, []);

  useEffect(() => {
    if (tl.current) tl.current.pause();
    tl.current = gsap.timeline();
    if (gameState === "rolling") {
      setRollNumber(Math.ceil(Math.random() * 4) + 1);
      //show starting title
      tl.current.set(el.current, { zIndex: 2100 });
      tl.current.fromTo(
        el.current,
        { y: 200, scale: 0.5, autoAlpha: 0 },
        {
          autoAlpha: 1,
          y: 0,
          scale: 1,
          duration: 0.5,
          ease: "back.out",
        }
      );
      tl.current.call(() => {
        setPaused(false);
        setPlaySound("dice");
      });
      tl.current.fromTo(
        numberText.current,
        { y: 200, scale: 0.5, autoAlpha: 0 },
        {
          delay: 1,
          autoAlpha: 1,
          y: 0,
          scale: 1,
          duration: 0.5,
          ease: "back.out",
        }
      );
      // Delay before we start to move car in AR
      tl.current.call(
        () => {
          setGameState("movingPlayer");
        },
        null,
        2.5
      );
    } else {
      // hide
      tl.current.to(el.current, {
        y: 200,
        scale: 0.5,
        autoAlpha: 0,
        ease: "back.in",
      });
      tl.current.set(el.current, { zIndex: -1 });
      tl.current.call(() => {
        setPaused(true);
      });
    }
  }, [gameState]);

  return (
    <div ref={el} className="roll-ui">
      <div className="dice-anaimation">
        {/* <img
          className="icon"
          src="../../assets/images/tap-to-roll.png"
          alt="start"
        /> */}
        {images && rollNumber && (
          <ImageSeqeuncePlayer
            frameRate={15}
            images={images[rollNumber]}
            width={205}
            height={205}
            paused={paused}
            loop={false}
          />
        )}
      </div>
      <h1 ref={numberText} data-text={rollNumber} className="number">
        {rollNumber}
      </h1>
    </div>
  );
}

export default RollUI;
